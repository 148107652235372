import AddGoalView from "./AddGoalView";
import AddImageModalController from "../../image/add_image_modal/AddImageModalController";
import { auth } from "../../../config/firebase";
import confetti from "canvas-confetti";
import dayjs from "dayjs";
import { Empty } from "@beyondrealityapp/core/shared/constants";
import { functions } from "../../../config/firebase";
import {
  GoalFormType,
  ImprovedGoal,
  improvedGoalAIRequest,
  improvedGoalAIResponse,
} from "@beyondrealityapp/core/goal/types";
import { GoalStatus } from "@beyondrealityapp/core/goal/constants";
import { httpsCallable, HttpsCallable } from "firebase/functions";
import { object, string, array, date } from "yup";
import { Page } from "@beyondrealityapp/core/shared/constants";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import {
  useCreateUpdateGoalMutation,
  useDeleteGoalMutation,
  useFetchGoalQuery,
} from "../../../api/goals";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";

const AddGoalController = () => {
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [isLoadingImprovedGoal, setIsLoadingImprovedGoal] = useState(false);
  const [openSections, setOpenSections] = useState<{
    [key: string]: boolean;
  }>({
    description: false,
    unit: false,
    themes: false,
    dates: false,
  });
  const [currentTab, setCurrentTab] = useState<number>(0);

  const { goalId } = useParams<{ goalId: string }>();
  const [searchParams] = useSearchParams();

  const [improvedGoal, setImprovedGoal] = useState<ImprovedGoal>({
    name: Empty.STRING,
    reason: Empty.STRING,
  });

  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const [createUpdateGoal] = useCreateUpdateGoalMutation();
  const [deleteGoal] = useDeleteGoalMutation();
  const {
    data: goal,
    isLoading,
    isError,
    error,
  } = useFetchGoalQuery(goalId, {
    skip: !goalId || goalId === "-1",
  });

  const GoalSchema = object({
    id: string(),
    content: string().required("Please make sure to add a goal"),
    description: string(),
    kpi: string(),
    target: string(),
    status: string(),
    current: string(),
    themes: array(string()).max(10, "Max 10 themes allowed"),
    startDate: date().nullable().default(null),
    endDate: date().nullable().default(null),
  });

  const initialValues: Partial<GoalFormType> = {
    id: Empty.STRING,
    uid: Empty.STRING,
    emoji: Empty.STRING,
    content: Empty.STRING,
    image: Empty.STRING,
    imageVerticalOffset: 50,
    description: Empty.STRING,
    status: Empty.STRING,
    kpi: Empty.STRING,
    target: Empty.STRING,
    current: Empty.STRING,
    startDate: null,
    endDate: null,
    completedAt: null,
    parent: Empty.STRING,
    themes: [],
    history: [],
  };

  const onSubmit = async () => {
    setSubmitting(true);
    createUpdateGoal(formik.values)
      .unwrap()
      .then((goal) => {
        formik.values.status === GoalStatus.COMPLETED &&
          formik.values.completedAt === null &&
          confetti({
            particleCount: 100,
            spread: 80,
            origin: { x: 0.5, y: 0.8 },
            colors: [
              theme.palette.purple.main,
              theme.palette.blue.main,
              theme.palette.red.main,
              theme.palette.primary.main,
            ],
          });
        dispatch(
          showSnackbar({
            severity: Severity.SUCCESS,
            message:
              goal?.status === "Completed"
                ? "Congratulations! You've completed your goal!"
                : "Goal saved successfully!",
          })
        );
        if (goalId === "-1" && goal) {
          navigate(`/${Page.GOALS}/${goal.id}`, { replace: true });
        }
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            severity: Severity.ERROR,
            message: error.message,
          })
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: GoalSchema,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (goalId === "-1" && searchParams.size > 0) {
      formik.resetForm();
      setOpenSections({
        description: false,
        unit: false,
        themes: false,
        dates: false,
      });

      searchParams.forEach((value, key) => {
        if (key === "themes") {
          const themes = value.split(",");
          formik.setFieldValue(key, themes);
          setOpenSections((prevState) => ({
            ...prevState,
            themes: true,
          }));
          return;
        }
        if (key === "startDate" || key === "endDate") {
          formik.setFieldValue(key, dayjs(value));
          setOpenSections((prevState) => ({
            ...prevState,
            dates: true,
          }));
          return;
        }
        formik.setFieldValue(key, value);
      });
      return;
    }

    if (goal) {
      const goalForm = goal.toForm();
      formik.setValues(goalForm);
      setOpenSections({
        description: goalForm.description !== Empty.STRING,
        unit: goalForm.kpi !== Empty.STRING,
        themes: goalForm.themes !== undefined && goalForm.themes.length > 0,
        dates: goalForm.startDate !== null || goalForm.endDate !== null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal, goalId]);

  useEffect(() => {
    if (isError) {
      dispatch(
        showSnackbar({
          severity: Severity.ERROR,
          message:
            error instanceof Error ? error.message : "Something went wrong",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    const handleSave = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    window.addEventListener("keydown", handleSave);
    return () => window.removeEventListener("keydown", handleSave);
  }, [formik]);

  const handleAddImageButtonClick = () => {
    setOpenImageModal(true);
  };

  const handleAddImageModalClose = () => {
    setOpenImageModal(false);
  };

  const handleSetImage = (url: string) => {
    formik.setFieldValue("image", url);
  };

  const onSetGoalHandler = () => {
    formik.setFieldValue("content", improvedGoal.name);
    setImprovedGoal({
      name: Empty.STRING,
      reason: Empty.STRING,
    });
  };

  const improveGoalHandler = async () => {
    const improveGoal: HttpsCallable<improvedGoalAIRequest> = httpsCallable(
      functions,
      "improveGoal"
    );

    if (formik.values.content === "") {
      setImprovedGoal({
        name: "Please enter a goal first",
        reason: "Use the AI to improve your goal",
      });
      return;
    }
    setIsLoadingImprovedGoal(true);

    improveGoal({ goalContent: formik.values.content })
      .then((result: improvedGoalAIResponse) => {
        setImprovedGoal({
          name: result.data.content,
          reason: result.data.reason,
        });
      })
      .catch((error) => {
        setImprovedGoal({
          name: "Something went wrong, please try again",
          reason: "Use the AI to improve your goal",
        });
      })
      .finally(() => {
        setIsLoadingImprovedGoal(false);
      });
  };

  const deleteHandler = () => {
    setDeleting(true);
    deleteGoal({
      goalId: formik.values.id ?? Empty.STRING,
      parentGoalId: formik.values.parent ?? Empty.STRING,
    })
      .unwrap()
      .then(() => {
        formik.resetForm();
        dispatch(
          showSnackbar({
            severity: Severity.SUCCESS,
            message: "Goal deleted successfully!",
          })
        );
        navigate(`/${Page.GOALS}`);
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            severity: Severity.ERROR,
            message: error.message,
          })
        );
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const handleSectionToggle = (section: string) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleTabChange = (event: React.SyntheticEvent, index: number) => {
    setCurrentTab(index);
  };

  return (
    <>
      <AddGoalView
        formik={formik}
        readonly={
          formik.values.uid !== auth.currentUser?.uid && goalId !== "-1"
        }
        isLoading={isLoading}
        backgroundColor={theme.palette.background.paper}
        openSections={openSections}
        handleSectionToggle={handleSectionToggle}
        handleAddImageButtonClick={handleAddImageButtonClick}
        improvedGoal={improvedGoal}
        onSetGoalHandler={onSetGoalHandler}
        isLoadingImprovedGoal={isLoadingImprovedGoal}
        improveGoalHandler={improveGoalHandler}
        currentTab={currentTab}
        handleTabChange={handleTabChange}
        deleteHandler={deleteHandler}
        submitting={submitting}
        deleting={deleting}
      />
      {openImageModal && (
        <AddImageModalController
          open={openImageModal}
          onClose={handleAddImageModalClose}
          setImageHandler={handleSetImage}
        />
      )}
    </>
  );
};

export default AddGoalController;
