import Button from "@mui/material/Button";

type NewReflectionButtonProps = {
  onClick: () => void;
};

const NewRelfectionButton: React.FC<NewReflectionButtonProps> = ({
  onClick,
}) => {
  return (
    <>
      <Button variant="contained" color="primary" onClick={onClick}>
        New Reflection
      </Button>
    </>
  );
};

export default NewRelfectionButton;
