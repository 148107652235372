import { auth, db } from "../config/firebase";
import { arrayUnion, getDoc, doc, setDoc } from "firebase/firestore";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { ErrorMessage } from "@beyondrealityapp/core/shared/constants";
import { functions } from "../config/firebase";
import { HttpsCallable } from "@firebase/functions";
import { httpsCallable } from "firebase/functions";
import {
  ManageBuddiesPayload,
  ManageBuddiesResponse,
} from "@beyondrealityapp/core/user/types";
import { Model } from "@beyondrealityapp/core/shared/constants";
import { NotificationSettings } from "@beyondrealityapp/core/user/types";
import { updateProfile, updateEmail } from "firebase/auth";
import { User } from "@beyondrealityapp/core/user/classes";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    fetchUser: builder.query({
      queryFn: async (id: string | undefined) => {
        if (!id) {
          return { error: new Error("No user ID provided") };
        }
        try {
          const user = await _fetchUser(id);
          return { data: user };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: ["User"],
    }),
    updateUser: builder.mutation({
      queryFn: async (user: User) => {
        try {
          await _updateUser(user);
          return { data: user };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["User"],
    }),
    addBuddy: builder.mutation({
      queryFn: async (buddyEmail: string) => {
        try {
          const response = await _addBuddy(buddyEmail);
          return { data: response };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["User"],
    }),
    removeBuddy: builder.mutation({
      queryFn: async (buddyId: string) => {
        try {
          const response = await _removeBuddy(buddyId);
          return { data: response };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["User"],
    }),
    updateNotificationSettings: builder.mutation({
      queryFn: async (notificationSettings: NotificationSettings) => {
        try {
          await _updateNotificationSettings(notificationSettings);
          return { data: notificationSettings };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["User"],
    }),
    persistPushNotificationToken: builder.mutation({
      queryFn: async (token: string) => {
        try {
          await _persistPushNotificationToken(token);
          return { data: token };
        } catch (error) {
          return { error: error as Error };
        }
      },
    }),
  }),
});

export const {
  useFetchUserQuery,
  useUpdateUserMutation,
  useAddBuddyMutation,
  useRemoveBuddyMutation,
  useUpdateNotificationSettingsMutation,
  usePersistPushNotificationTokenMutation,
} = userApi;

export const _fetchUser = async (id: string) => {
  return new Promise<User>((resolve, reject) => {
    const userRef = doc(db, Model.USERS, id);
    getDoc(userRef)
      .then((doc) => {
        if (doc.exists()) {
          const user = User.fromFirestore(doc.data());
          resolve(user);
        } else {
          reject(new Error(ErrorMessage.NO_ID));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _updateUser = async (user: User) => {
  user.init(auth);
  return new Promise<void>((resolve, reject) => {
    if (auth.currentUser) {
      updateProfile(auth.currentUser, {
        displayName: user.name,
        photoURL: user.profilePicture,
      })
        .then(() => {
          const userRef = doc(db, Model.USERS, user.uid);
          setDoc(userRef, user.toFirestore())
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    }
    if (auth.currentUser && auth.currentUser.email !== user.email) {
      updateEmail(auth.currentUser, user.email)
        .then(() => {
          const userRef = doc(db, Model.USERS, user.uid);
          setDoc(userRef, user.toFirestore())
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};

const _addBuddy = async (buddyEmail: string) => {
  return new Promise<ManageBuddiesResponse>((resolve, reject) => {
    const manageBuddies: HttpsCallable<
      ManageBuddiesPayload,
      ManageBuddiesResponse
    > = httpsCallable(functions, "manageBuddies");

    manageBuddies({
      action: "add",
      buddyEmail,
    })
      .then((status) => {
        resolve(status.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _removeBuddy = async (buddyEmail: string) => {
  return new Promise<void>((resolve, reject) => {
    const manageBuddies: HttpsCallable<
      ManageBuddiesPayload,
      ManageBuddiesResponse
    > = httpsCallable(functions, "manageBuddies");

    manageBuddies({
      action: "remove",
      buddyEmail,
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _updateNotificationSettings = async (
  notificationSettings: NotificationSettings
) => {
  return new Promise<void>((resolve, reject) => {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      reject(new Error(ErrorMessage.NO_ID));
      return;
    }
    const userRef = doc(db, Model.USERS, uid);
    setDoc(userRef, { notifications: notificationSettings }, { merge: true })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _persistPushNotificationToken = async (token: string) => {
  return new Promise<void>((resolve, reject) => {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      reject(new Error(ErrorMessage.NO_ID));
      return;
    }

    const pushNotificationToken = `pushNotificationToken${uid}`;

    const localToken = localStorage.getItem(pushNotificationToken);
    if (localToken === token) {
      resolve();
      return;
    }

    localStorage.setItem(pushNotificationToken, token);

    const userRef = doc(db, Model.USERS, uid);
    setDoc(
      userRef,
      { pushNotificationTokens: arrayUnion(token) },
      { merge: true }
    )
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};
