import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuController from "./menu/MenuController";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import UserProfileAvatarController from "./user_profile_avatar/UserProfileAvatarController";

type NavbarViewProps = {
  navgationMenuOpen: boolean;
  onNavigationMenuClick: () => void;
  onBackdropClick: () => void;
  onLogoClick: () => void;
};

const NavBarView: React.FC<NavbarViewProps> = ({
  navgationMenuOpen,
  onNavigationMenuClick,
  onBackdropClick,
  onLogoClick,
}) => {
  return (
    <>
      <AppBar position="sticky" color="inherit">
        <MenuController
          open={navgationMenuOpen}
          onClose={onBackdropClick}
          onOpen={onNavigationMenuClick}
        />
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="primary"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={onNavigationMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: "flex",
                  flexDirection: "row",
                  cursor: "pointer",
                }}
                onClick={onLogoClick}
              >
                <Typography variant="h6" color="primary">
                  Beyond
                </Typography>
                <Typography variant="h6">Reality</Typography>
              </Box>
            </Box>
          </Box>
          <UserProfileAvatarController />
        </Toolbar>
      </AppBar>
      <Outlet />
    </>
  );
};

export default NavBarView;
