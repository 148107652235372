import BackButtonController from "../components/layout/back_button/BackButtonController";
import PageContainer from "../components/layout/PageContainer";
import ReflectionTemplateFormController from "../components/reflections/reflection_template_form/ReflectionTemplateFormController";

const ReflectionTemplateDetails = () => {
  return (
    <PageContainer>
      <BackButtonController />
      <ReflectionTemplateFormController />
    </PageContainer>
  );
};

export default ReflectionTemplateDetails;
