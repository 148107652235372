import PageContainer from "../components/layout/PageContainer";
import ReflectionFormController from "../components/reflections/reflection_form/ReflectionFormController";

const ReflectionDetails = () => {
  return (
    <PageContainer>
      <ReflectionFormController />
    </PageContainer>
  );
};

export default ReflectionDetails;
