import { useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { object, string, array } from "yup";
import {
  generateInitialValues,
  generateValidationSchema,
} from "./reflectionFormUtils";
import { ReflectionFormType } from "@beyondrealityapp/core/reflection/types";
import ReflectionFormView from "./ReflectionFormView";
import {
  useUpdateReflectionMutation,
  useFetchReflectionQuery,
  useDeleteReflectionMutation,
} from "../../../api/reflection";
import { showSnackbar } from "../../../features/snackbar";
import {
  ErrorMessage,
  Page,
  Severity,
} from "@beyondrealityapp/core/shared/constants";
import { auth } from "../../../config/firebase";

const ReflectionFormController = () => {
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reflectionId } = useParams();

  const [updateReflection] = useUpdateReflectionMutation();
  const [deleteReflection] = useDeleteReflectionMutation();

  const {
    data: reflection,
    isError,
    error,
    isLoading,
  } = useFetchReflectionQuery(reflectionId, {
    skip: !reflectionId,
  });

  // Build validation schema dynamically based on questions
  const validationSchema = useMemo(() => {
    if (reflection) {
      return generateValidationSchema(reflection);
    }

    // Default validation schema for new reflection
    return object().shape({
      title: string(),
      description: string(),
      questions: array().of(
        object().shape({
          value: string().nullable(),
        })
      ),
    });
  }, [reflection]);

  const onSubmit = async (values: ReflectionFormType) => {
    setSubmitting(true);
    updateReflection(values)
      .unwrap()
      .then(() => {
        dispatch(
          showSnackbar({
            message: "Reflection saved successfully",
            severity: Severity.SUCCESS,
          })
        );
      })
      .catch((error) => {
        const errorMessage =
          error instanceof Error ? error.message : ErrorMessage.UNKNOWN_ERROR;

        dispatch(
          showSnackbar({
            message: errorMessage,
            severity: Severity.ERROR,
          })
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleDelete = async () => {
    if (!reflectionId) return;

    setDeleting(true);

    deleteReflection(reflectionId)
      .unwrap()
      .then(() => {
        dispatch(
          showSnackbar({
            message: "Reflection deleted successfully",
            severity: Severity.SUCCESS,
          })
        );
        navigate(`/${Page.REFLECTIONS}`);
      })
      .catch((error) => {
        const errorMessage =
          error instanceof Error ? error.message : ErrorMessage.UNKNOWN_ERROR;

        dispatch(
          showSnackbar({
            message: errorMessage,
            severity: Severity.ERROR,
          })
        );
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const formik = useFormik({
    initialValues: generateInitialValues(reflection),
    validationSchema,
    onSubmit,
  });

  const isReadOnly = useMemo(() => {
    return reflection?.uid !== auth.currentUser?.uid;
  }, [reflection?.uid]);

  useEffect(() => {
    if (reflection) {
      formik.setValues(generateInitialValues(reflection));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reflection]);

  // Handle API errors
  useEffect(() => {
    if (isError && error) {
      const errorMessage =
        error instanceof Error ? error.message : ErrorMessage.UNKNOWN_ERROR;

      dispatch(
        showSnackbar({
          message: errorMessage,
          severity: Severity.ERROR,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, error, dispatch]);

  return (
    <ReflectionFormView
      formik={formik}
      readonly={isReadOnly}
      isLoading={isLoading}
      submitting={submitting}
      deleting={deleting}
      handleDelete={handleDelete}
    />
  );
};

export default ReflectionFormController;
