import AddIcon from "@mui/icons-material/Add";
import { Alert, Collapse } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import React, { ChangeEventHandler } from "react";
import { SelectChangeEvent } from "@mui/material";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { TransitionGroup } from "react-transition-group";

type QuestionTemplateFormViewProps = {
  name: string;
  types: {
    value: string;
    label: string;
  }[];
  selectedType: string[];
  onChangeText: ChangeEventHandler<HTMLInputElement>;
  onChangeType: (
    event: SelectChangeEvent<string[]>,
    child: React.ReactNode
  ) => void;
  onDeleteQuestion: () => void;
  questionError?: string;
  hasChoices: boolean;
  onAddChoice: (value: string) => void;
  onDeleteChoice: (index: number) => void;
  onChangeChoices: (index: number, value: string) => void;
  onChoiceClickUp: (index: number) => void;
  onChoiceClickDown: (index: number) => void;
  choices?: string[];
  choicesError?: string;
};

const QuestionTemplateView: React.FC<QuestionTemplateFormViewProps> =
  React.memo(
    ({
      name,
      types,
      selectedType,
      onChangeText,
      onChangeType,
      onDeleteQuestion,
      questionError,
      hasChoices,
      onAddChoice,
      onDeleteChoice: deleteChoice,
      onChangeChoices,
      onChoiceClickUp,
      onChoiceClickDown,
      choices,
      choicesError,
    }) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 1,
            gap: 1,
          }}
        >
          <TextField
            id="name"
            name="name"
            label="Question"
            value={name}
            onChange={onChangeText}
            helperText={questionError}
            error={Boolean(questionError)}
            required
          />
          <Select
            id="type"
            name="type"
            value={selectedType}
            onChange={onChangeType}
            required
          >
            {types.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
          {hasChoices && (
            <>
              <List
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <TransitionGroup>
                  {choices && choices.map((choice, index) => (
                    <Collapse key={index}>
                      <ListItem key={index} disablePadding>
                        <Card
                          variant="outlined"
                          sx={{
                            padding: 1,
                            marginTop: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <TextField
                              id={`choice-${name}-${index}`}
                              name={`choice-${name}-${index}`}
                              value={choice}
                              onChange={(event) => {
                                onChangeChoices(index, event.target.value);
                              }}
                              placeholder={`Choice ${index + 1}`}
                              size="small"
                              required
                            />
                            <IconButton
                              size="small"
                              onClick={() => {
                                onChoiceClickUp(index);
                              }}
                              disabled={index === 0}
                            >
                              <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => {
                                onChoiceClickDown(index);
                              }}
                              disabled={index === choices.length - 1}
                            >
                              <ArrowDownwardIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => {
                                deleteChoice(index);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Card>
                      </ListItem>
                    </Collapse>
                  ))}
                </TransitionGroup>
              </List>
              <Button
                startIcon={<AddIcon />}
                size="small"
                variant="text"
                onClick={() => {
                  onAddChoice("");
                }}
              >
                Add Choice
              </Button>
            </>
          )}
          <Collapse in={Boolean(choicesError)}>
            <Alert severity="error">{choicesError}</Alert>
          </Collapse>
          <Button variant="text" color="error" onClick={onDeleteQuestion}>
            Delete question
          </Button>
        </Box>
      );
    }
  );

export default QuestionTemplateView;
