import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import DefaultModal from "../../layout/DefaultModal";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { ReflectionTemplateClass } from "@beyondrealityapp/core/reflection/template/classes";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";

type NewReflectionModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitting: boolean;
  isError: boolean;
  error: Error;
  isLoading: boolean;
  value: string;
  reflectionTemplates: ReflectionTemplateClass[];
  onChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
};

const NewReflectionModal: React.FC<NewReflectionModalProps> = ({
  open,
  onClose,
  onSubmit,
  submitting,
  isError,
  error,
  isLoading,
  value,
  reflectionTemplates,
  onChange,
}) => {
  return (
    <DefaultModal isOpen={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h5" gutterBottom textAlign="center">
          New Reflection
        </Typography>
        <Typography variant="body2" color="textSecondary" textAlign="center">
          Select a reflection template to create a new reflection.
        </Typography>
        <FormControl margin="dense" fullWidth>
          <InputLabel id="reflections" variant="standard">
            Reflections
          </InputLabel>
          <Select
            id="reflections"
            name="reflections"
            label="Reflections"
            variant="standard"
            placeholder="Select reflections"
            value={value}
            onChange={onChange}
            disabled={isLoading || isError}
            renderValue={(selected) => {
              const selectedTemplate = reflectionTemplates.find(
                (template) => template.id === selected
              );
              return selectedTemplate ? selectedTemplate.title : selected;
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            {isLoading && (
              <MenuItem disabled value="">
                Loading reflections...
              </MenuItem>
            )}
            {isError && (
              <MenuItem disabled value="">
                {error instanceof Error && error.message}
              </MenuItem>
            )}
            {!isLoading && !isError && reflectionTemplates.length === 0 && (
              <MenuItem disabled value="">
                Go to the "Reflection Templates" tab to add a template
              </MenuItem>
            )}
            {!isLoading &&
              !isError &&
              reflectionTemplates.map((reflectionTemplate) => (
                <MenuItem
                  key={reflectionTemplate.id}
                  value={reflectionTemplate.id}
                >
                  <ListItemText primary={reflectionTemplate.title} />
                </MenuItem>
              ))}
          </Select>
          <Collapse in={Boolean(value) && !isLoading && !isError}>
            <Typography variant="body2" color="textSecondary" marginTop={1}>
              {reflectionTemplates.find((template) => template.id === value)
                ?.description || "No description available."}
            </Typography>
          </Collapse>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 2,
          }}
        >
          <Button variant="text" onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit} disabled={!value}>
            {submitting ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              "Create Reflection"
            )}
          </Button>
        </Box>
      </Box>
    </DefaultModal>
  );
};

export default NewReflectionModal;
