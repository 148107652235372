import NavBarView from "./NavBarView";
import { useState } from "react";
import { useNavigate } from "react-router";

const NavBarController = () => {
  const navigate = useNavigate();
  const [navgationMenuOpen, setNavgationMenuOpen] = useState(false);

  const onLogoClick = () => {
    navigate("/");
  };

  const onNavigationMenuClick = () => {
    setNavgationMenuOpen(!navgationMenuOpen);
  };

  const onBackdropClick = () => {
    setNavgationMenuOpen(false);
  };

  return (
    <NavBarView
      navgationMenuOpen={navgationMenuOpen}
      onNavigationMenuClick={onNavigationMenuClick}
      onBackdropClick={onBackdropClick}
      onLogoClick={onLogoClick}
    />
  );
};

export default NavBarController;
