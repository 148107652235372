import ReflectionTemplateCardView from "./ReflectionTemplateCardView";

type ReflectionTemplateCardControllerProps = {
  id: string;
  title: string;
  description: string;
  onClick: (reflectionTemplateId: string) => void;
  image?: string;
};

const ReflectionTemplateCardController: React.FC<
  ReflectionTemplateCardControllerProps
> = ({ id, title, description, image, onClick }) => {
  return (
    <ReflectionTemplateCardView
      id={id}
      title={title}
      description={description}
      image={image}
      onClick={onClick}
    />
  );
};

export default ReflectionTemplateCardController;
