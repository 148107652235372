import AddIcon from "@mui/icons-material/Add";
import { Alert, Collapse } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DraggableListController from "../../layout/draggable_list/DraggableListController";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  FormikState,
  FormikHelpers,
  FormikHandlers,
  FormikErrors,
} from "formik";
import FormContainer from "../../layout/FormContainer";
import { getIn } from "formik";
import LoadingSpinner from "../../layout/LoadingSpinner";
import { OnDragEndResponder } from "@hello-pangea/dnd";
import QuestionTemplateController from "../../questions/question_template/QuestionTemplateController";
import React from "react";
import { ReflectionTemplateFormType } from "@beyondrealityapp/core/reflection/template/types";
import SubmitDeleteActions from "../../layout/SubmitDeleteActions";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { SelectChangeEvent } from "@mui/material";
import { QuestionTemplateType } from "@beyondrealityapp/core/question/template/types";

type ReflectionTemplateFormViewProps = {
  formik: FormikState<ReflectionTemplateFormType> &
    FormikHelpers<ReflectionTemplateFormType> &
    FormikHandlers;
  onAddImage: () => void;
  addQuestion: () => void;
  onDeleteQuestion: (questionIndex: number) => void;
  onChangeType: (event: SelectChangeEvent<string[]>, index: number) => void;
  onDragEnd: OnDragEndResponder;
  readonly: boolean;
  isLoading: boolean;
  submitting: boolean;
  deleting: boolean;
  handleDelete: () => void;
  onAddChoice: (questionIndex: number, value: string) => void;
  onDeleteChoice: (questionIndex: number, choiceIndex: number) => void;
  onChangeChoices: (
    questionIndex: number,
    choiceIndex: number,
    value: string
  ) => void;
  onChoiceClickUp: (questionIndex: number, choiceIndex: number) => void;
  onChoiceClickDown: (questionIndex: number, choiceIndex: number) => void;
};

const ReflectionTemplateFormView: React.FC<ReflectionTemplateFormViewProps> =
  React.memo(
    ({
      formik,
      onAddImage,
      addQuestion,
      onDeleteQuestion,
      onChangeType,
      onDragEnd,
      readonly,
      isLoading,
      submitting,
      deleting,
      handleDelete,
      onAddChoice,
      onDeleteChoice,
      onChangeChoices,
      onChoiceClickUp,
      onChoiceClickDown,
    }) => {
      const showQuestionsError = (
        questionErrors?:
          | string
          | string[]
          | FormikErrors<QuestionTemplateType>[]
      ) => {
        if (questionErrors === undefined) return null;
        if (typeof questionErrors === "string") return questionErrors;
        if (Array.isArray(questionErrors)) {
          const errorMessage = questionErrors[0];
          if (typeof errorMessage === "string") return errorMessage;
          if (typeof errorMessage === "object") {
            return errorMessage.name;
          }
        }
      };

      return (
        <FormContainer>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography variant="h5" gutterBottom textAlign="center">
              Reflection Template
            </Typography>
            <Typography variant="body2" gutterBottom textAlign="center">
              Fill out the form below to create a reflection template. Make sure
              to fill in the title and description and add at least one
              question.
            </Typography>
            {isLoading && (
              <Box marginTop={2}>
                <LoadingSpinner />
              </Box>
            )}
            {!isLoading && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    gap: 2,
                    marginTop: 2,
                  }}
                >
                  <TextField
                    id="title"
                    name="title"
                    label="Title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    disabled={readonly}
                    required
                  />
                  <TextField
                    id="description"
                    name="description"
                    label="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                    disabled={readonly}
                    required
                    multiline
                    rows={4}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      marginTop: 2,
                    }}
                  >
                    <Typography variant="h6" textAlign="center">
                      Questions
                    </Typography>
                    <DraggableListController
                      id="questionTemplateList"
                      listItems={formik.values.questions.map((_, index) => (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <DragIndicatorIcon
                              sx={{
                                transform: "rotate(90deg)",
                              }}
                            />
                          </Box>
                          <QuestionTemplateController
                            key={index}
                            name={formik.values.questions[index].name}
                            selectedType={[formik.values.questions[index].type]}
                            onChangeText={(event) =>
                              formik.setFieldValue(
                                `questions[${index}].name`,
                                event.target.value
                              )
                            }
                            onChangeType={(event) => onChangeType(event, index)}
                            onDeleteQuestion={() => onDeleteQuestion(index)}
                            questionError={
                              getIn(
                                formik.touched,
                                `questions[${index}].name`
                              ) &&
                              getIn(formik.errors, `questions[${index}].name`)
                            }
                            onAddChoice={(value) => onAddChoice(index, value)}
                            onDeleteChoice={(choiceIndex) =>
                              onDeleteChoice(index, choiceIndex)
                            }
                            onChangeChoices={(choiceIndex, value) =>
                              onChangeChoices(index, choiceIndex, value)
                            }
                            onChoiceClickUp={(choiceIndex) =>
                              onChoiceClickUp(index, choiceIndex)
                            }
                            onChoiceClickDown={(choiceIndex) =>
                              onChoiceClickDown(index, choiceIndex)
                            }
                            choices={
                              formik.values.questions[index].choices || []
                            }
                            choicesError={getIn(
                              formik.errors,
                              `questions[${index}].choices`
                            )}
                          />
                        </>
                      ))}
                      onDragEnd={onDragEnd}
                    />
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={addQuestion}
                    >
                      Add Question
                    </Button>
                    <Collapse
                      in={Boolean(
                        formik.errors.questions && formik.touched.questions
                      )}
                    >
                      <Alert severity="error" sx={{ marginTop: 2 }}>
                        {showQuestionsError(formik.errors.questions)}
                      </Alert>
                    </Collapse>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      {!readonly && (
                        <SubmitDeleteActions
                          allowDelete={formik.values.id !== ""}
                          submitting={submitting}
                          deleting={deleting}
                          deleteHandler={handleDelete}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </FormContainer>
      );
    }
  );

export default ReflectionTemplateFormView;
