import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import {
  GoalFormType,
  GoalStatusType,
  ImprovedGoal,
} from "@beyondrealityapp/core/goal/types";
import { FormikHandlers, FormikHelpers, FormikState } from "formik";
import {
  AddAPhoto,
  AdsClickRounded,
  ScaleRounded,
  TimerRounded,
} from "@mui/icons-material";
import MultipleChoice from "../../layout/MultipleChoice";
import ThemeMultiSelectController from "../../themes/themes_multi_select/ThemeMultiSelectController";
import { DatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import SubmitDeleteActions from "../../layout/SubmitDeleteActions";
import LoadingSpinner from "../../layout/LoadingSpinner";
import SubGoalsTab from "./SubGoalsTab";
import CardImageHeaderController from "../../image/card_image_header/CardImageHeaderController";
import { GoalClass } from "@beyondrealityapp/core/goal/classes";

const statusOptions: GoalStatusType[] = [
  "Not started",
  "In progress",
  "Completed",
  "Overdue",
  "",
];

type AddGoalViewProps = {
  readonly: boolean;
  formik: FormikState<Partial<GoalFormType>> &
    FormikHelpers<Partial<GoalFormType>> &
    FormikHandlers;
  isLoading: boolean;
  backgroundColor: string;
  openSections: { [key: string]: boolean };
  handleSectionToggle: (section: string) => void;
  handleAddImageButtonClick: () => void;
  improvedGoal: ImprovedGoal;
  isLoadingImprovedGoal: boolean;
  improveGoalHandler: () => void;
  onSetGoalHandler: () => void;
  currentTab: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  deleteHandler: () => void;
  submitting: boolean;
  deleting: boolean;
};

const AddGoalView: React.FC<AddGoalViewProps> = ({
  readonly,
  formik,
  isLoading,
  backgroundColor,
  openSections,
  handleSectionToggle,
  handleAddImageButtonClick,
  improvedGoal,
  isLoadingImprovedGoal,
  improveGoalHandler,
  onSetGoalHandler,
  currentTab,
  handleTabChange,
  deleteHandler,
  submitting,
  deleting,
}) => {
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: backgroundColor,
        padding: 2,
        borderRadius: 4,
      }}
    >
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          {formik.values.image && (
            <CardImageHeaderController
              imageUrl={formik.values.image ?? ""}
              verticalOffset={formik.values.imageVerticalOffset ?? 0}
              offSetHandler={(offset: number) =>
                formik.setFieldValue("imageVerticalOffset", offset, true)
              }
              imageDeleteHandler={() => formik.setFieldValue("image", "", true)}
              readonly={readonly}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flex: 1,
            }}
          >
            <TextField
              autoFocus
              id="goal-content"
              label="What's your goal?"
              placeholder="Enter the description of your goal here..."
              variant="standard"
              sx={{
                flex: 1,
                "& .MuiInput-input": {
                  fontWeight: "600",
                },
              }}
              name="content"
              multiline
              disabled={readonly}
              value={formik.values.content}
              onChange={formik.handleChange}
              error={formik.touched.content && Boolean(formik.errors.content)}
              helperText={formik.touched.content && formik.errors.content}
              InputLabelProps={{
                sx: { fontWeight: "500" },
              }}
            />
            <IconButton
              aria-label="add-image"
              onClick={handleAddImageButtonClick}
              color="primary"
              disabled={readonly}
            >
              <AddAPhoto />
            </IconButton>
          </Box>
          <Collapse in={improvedGoal.name !== ""}>
            <Alert
              severity="info"
              sx={{ marginTop: 2 }}
              icon={false}
              action={
                <Button color="inherit" onClick={onSetGoalHandler}>
                  SET AS GOAL
                </Button>
              }
            >
              <AlertTitle>
                <strong>{improvedGoal.name}</strong>
              </AlertTitle>
              {improvedGoal.reason}
            </Alert>
          </Collapse>
          <Button
            size="small"
            variant="text"
            onClick={improveGoalHandler}
            disabled={readonly}
            sx={{
              marginTop: 1,
              alignSelf: "flex-start",
            }}
          >
            {isLoadingImprovedGoal ? (
              <CircularProgress size={16} />
            ) : (
              "Improve Goal"
            )}
          </Button>
          <Grid container columnSpacing={1} marginBottom={1}>
            {Object.keys(openSections).map((section) => {
              return (
                <Grid item marginTop={1} key={section}>
                  <Chip
                    label={
                      openSections[section]
                        ? `Hide ${section}`
                        : `Show ${section}`
                    }
                    variant={"outlined"}
                    onClick={() => handleSectionToggle(section)}
                  />
                </Grid>
              );
            })}
            <Grid item marginTop={1} key={"Status"}>
              <MultipleChoice
                value={formik.values.status ?? ""}
                onChange={formik.handleChange}
                statusOptions={statusOptions}
                placeholder="Status"
                readonly={readonly}
              />
            </Grid>
          </Grid>
          <Collapse in={openSections.description}>
            <TextField
              id="description"
              name="description"
              label="Detailed description"
              variant="standard"
              placeholder="Describe your goal"
              value={formik.values.description}
              onChange={formik.handleChange}
              disabled={readonly}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              fullWidth
              multiline
            />
          </Collapse>
          <Collapse in={openSections.themes}>
            <ThemeMultiSelectController
              onChange={formik.handleChange}
              value={formik.values.themes ?? []}
              error={formik.touched.themes && Boolean(formik.errors.themes)}
              helperText={formik.errors.themes}
              disabled={readonly}
            />
          </Collapse>
          <Collapse in={openSections.unit}>
            <Grid container columnSpacing={1} marginTop={1}>
              <Grid item xs={4}>
                <TextField
                  id="current"
                  name="current"
                  label="Current"
                  variant="standard"
                  placeholder="0"
                  value={formik.values.current}
                  onChange={formik.handleChange}
                  disabled={readonly}
                  error={
                    formik.touched.current && Boolean(formik.errors.current)
                  }
                  helperText={formik.touched.current && formik.errors.current}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ScaleRounded fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="target"
                  name="target"
                  label="Target"
                  variant="standard"
                  placeholder="100"
                  value={formik.values.target}
                  onChange={formik.handleChange}
                  disabled={readonly}
                  error={formik.touched.target && Boolean(formik.errors.target)}
                  helperText={formik.touched.target && formik.errors.target}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AdsClickRounded fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="kpi"
                  name="kpi"
                  label="Unit"
                  variant="standard"
                  placeholder="%"
                  value={formik.values.kpi}
                  disabled={readonly}
                  onChange={formik.handleChange}
                  error={formik.touched.kpi && Boolean(formik.errors.kpi)}
                  helperText={formik.touched.kpi && formik.errors.kpi}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TimerRounded fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
          <Collapse in={openSections.dates}>
            <Grid container columnSpacing={3} marginTop={1}>
              <Grid item xs={6}>
                <DatePicker
                  label="Start Date"
                  value={formik.values.startDate}
                  disabled={readonly}
                  onChange={(date: Dayjs) =>
                    formik.setFieldValue("startDate", date, true)
                  }
                  slotProps={{
                    textField: {
                      name: "startDate",
                      id: "startDate",
                      variant: "standard",
                      error:
                        formik.touched.startDate &&
                        Boolean(formik.errors.startDate),
                      helperText:
                        formik.touched.startDate && formik.errors.startDate,
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label="End Date"
                  value={formik.values.endDate}
                  disabled={readonly}
                  onChange={(date: Dayjs) =>
                    formik.setFieldValue("endDate", date, true)
                  }
                  slotProps={{
                    textField: {
                      name: "endDate",
                      id: "endDate",
                      variant: "standard",
                      error:
                        formik.touched.endDate &&
                        Boolean(formik.errors.endDate),
                      helperText:
                        formik.touched.endDate && formik.errors.endDate,
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
          {!readonly ? (
            <Box marginTop={2}>
              <SubmitDeleteActions
                deleteHandler={deleteHandler}
                submitting={submitting}
                deleting={deleting}
                allowDelete={formik.values.id !== ""}
              />
            </Box>
          ) : null}
        </>
      )}
      <Divider sx={{ marginTop: 2 }} />
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab label="Subgoals" />
      </Tabs>
      {currentTab === 0 &&
        formik.values.id !== "" &&
        formik.values.id !== undefined && (
          <SubGoalsTab
            parentGoal={GoalClass.fromForm(formik.values)}
            readonly={readonly}
          />
        )}
      {currentTab === 0 && formik.values.id === "" && !isLoading && (
        <Alert severity="info" sx={{ marginTop: 2 }}>
          <AlertTitle>
            <strong>Save the goal first</strong>
          </AlertTitle>
          You need to save the goal before adding subgoals
        </Alert>
      )}
    </Box>
  );
};

export default AddGoalView;
