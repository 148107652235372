import Button from "@mui/material/Button";

type AddReflectionTemplateButtonProps = {
  onClick: () => void;
};

const AddReflectionTemplateButton: React.FC<
  AddReflectionTemplateButtonProps
> = ({ onClick }) => {
  return (
    <Button variant="contained" color="primary" onClick={onClick}>
      Add reflection template
    </Button>
  );
};

export default AddReflectionTemplateButton;
