import React from "react";
import QuestionTemplateView from "./QuestionTemplateView";
import { SelectChangeEvent } from "@mui/material";

const questionTypes = [
  {
    value: "single_line_text",
    label: "Single Line Text",
  },
  {
    value: "multi_line_text",
    label: "Multi Line Text",
  },
  {
    value: "multiple_choice",
    label: "Multiple Choice",
  },
  {
    value: "happiness_scale",
    label: "Happiness Scale",
  },
];

type QuestionTemplateControllerProps = {
  name: string;
  selectedType: string[];
  onChangeText: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeType: (
    event: SelectChangeEvent<string[]>,
    child: React.ReactNode
  ) => void;
  onDeleteQuestion: () => void;
  questionError?: string;
  onAddChoice: (value: string) => void;
  onDeleteChoice: (index: number) => void;
  onChangeChoices: (index: number, value: string) => void;
  onChoiceClickUp: (index: number) => void;
  onChoiceClickDown: (index: number) => void;
  choices?: string[];
  choicesError?: string;
};

const QuestionTemplateController: React.FC<QuestionTemplateControllerProps> = ({
  name,
  selectedType,
  onChangeText,
  onChangeType,
  onDeleteQuestion,
  questionError,
  onAddChoice,
  onDeleteChoice,
  onChangeChoices,
  onChoiceClickUp,
  onChoiceClickDown,
  choices,
  choicesError,
}) => {
  const hasChoices =
    selectedType.includes("multiple_choice") ||
    selectedType.includes("happiness_scale");

  return (
    <QuestionTemplateView
      name={name}
      types={questionTypes}
      selectedType={selectedType}
      onChangeText={onChangeText}
      onChangeType={onChangeType}
      onDeleteQuestion={onDeleteQuestion}
      questionError={questionError}
      hasChoices={hasChoices}
      onAddChoice={onAddChoice}
      onDeleteChoice={onDeleteChoice}
      onChangeChoices={onChangeChoices}
      onChoiceClickUp={onChoiceClickUp}
      onChoiceClickDown={onChoiceClickDown}
      choices={choices}
      choicesError={choicesError}
    />
  );
};

export default QuestionTemplateController;
