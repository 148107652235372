import Box from "@mui/material/Box";
import ListActions from "../../../layout/list_actions/ListActions";
import NewReflectionButtonController from "../../new_reflection_button/NewReflectionButtonController";
import NewReflectionModalController from "../../new_reflection_modal/NewReflectionModalController";
import ReflectionListController from "../../reflection_list/ReflectionListController";

type ReflectionsTabViewProps = {
  onClickNewReflection: () => void;
  onClose: () => void;
  newReflectionModalOpen: boolean;
};

const ReflectionsTabView: React.FC<ReflectionsTabViewProps> = ({
  onClickNewReflection,
  onClose,
  newReflectionModalOpen,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <ListActions>
        <NewReflectionButtonController onClick={onClickNewReflection} />
        {newReflectionModalOpen && (
          <NewReflectionModalController
            onClose={onClose}
            open={newReflectionModalOpen}
          />
        )}
      </ListActions>
      <ReflectionListController />
    </Box>
  );
};

export default ReflectionsTabView;
