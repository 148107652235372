import AddThemeCardButton from "../add_theme_card_button/AddThemeCardButton";
import Grid from "@mui/material/Grid";
import LoadingSpinner from "../../layout/LoadingSpinner";
import NoResults from "../../layout/NoResults";
import ThemeCardController from "../theme_card/ThemeCardController";
import { ThemeClass } from "@beyondrealityapp/core/theme/classes";
import Typography from "@mui/material/Typography";

type ThemesListViewProps = {
  themes: ThemeClass[];
  loading: boolean;
  isError: boolean;
  error?: unknown;
  readonly?: boolean;
};

const ThemesListView: React.FC<ThemesListViewProps> = ({
  themes,
  loading,
  isError,
  error,
  readonly,
}) => {
  const cardWidth = 250;
  const cardHeight = 250;

  let errorMessage = "An error occurred while fetching themes";
  if (error instanceof Error) {
    errorMessage = "⚠️ An error occurred: " + error.message;
  }

  return (
    <>
      {isError && <Typography>{errorMessage}</Typography>}
      {loading && !error && <LoadingSpinner />}
      {!loading && !error && !themes.length && (
        <NoResults message={"There are no themes available"} />
      )}
      <Grid container spacing={1} columns={{ xs: 2, sm: 8}}>
        {!loading &&
          !error &&
          themes.length > 0 &&
          themes.map((theme) => (
            <Grid
              item
              xs={2}
              sm={4}
              key={theme.id}
              height={cardHeight}
              width={cardWidth}
            >
              <ThemeCardController theme={theme} readonly={readonly} />
            </Grid>
          ))}
        {!readonly && (
          <Grid item xs={2} sm={4} height={cardHeight} width={cardWidth}>
            <AddThemeCardButton />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ThemesListView;
