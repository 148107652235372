import { auth, db } from "../config/firebase";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { Model, Order } from "@beyondrealityapp/core/shared/constants";
import { ReflectionTemplateField } from "@beyondrealityapp/core/reflection/template/constants";
import {
  ReflectionTemplateFirestoreType,
  ReflectionTemplateFormType,
} from "@beyondrealityapp/core/reflection/template/types";
import { ReflectionTemplateClass } from "@beyondrealityapp/core/reflection/template/classes";
import { ReflectionClass } from "@beyondrealityapp/core/reflection/classes";
import {
  ReflectionFormType,
  ReflectionFirestoreType,
} from "@beyondrealityapp/core/reflection/types";
import { ReflectionField } from "@beyondrealityapp/core/reflection/constants";

export const reflectionApi = createApi({
  reducerPath: "reflectionApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Reflection", "ReflectionTemplate"],
  endpoints: (builder) => ({
    createUpdateReflectionTemplate: builder.mutation({
      queryFn: async (
        reflectionTemplateForm?: Partial<ReflectionTemplateFormType>
      ) => {
        try {
          const reflectionTemplate = await _createUpdateReflectionTemplate(
            reflectionTemplateForm
          );
          return { data: reflectionTemplate };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["ReflectionTemplate"],
    }),
    fetchReflectionTemplate: builder.query({
      queryFn: async (reflectionTemplateId?: string) => {
        if (!reflectionTemplateId) {
          return { error: new Error("No reflection template ID provided") };
        }
        try {
          const reflectionTemplate = await _fetchReflectionTemplate(
            reflectionTemplateId
          );
          return { data: reflectionTemplate };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: ["ReflectionTemplate"],
    }),
    fetchReflectionTemplates: builder.query({
      queryFn: async () => {
        try {
          const reflectionTemplates = await _fetchReflectionTemplates();
          return { data: reflectionTemplates };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: ["ReflectionTemplate"],
    }),
    deleteReflectionTemplate: builder.mutation({
      queryFn: async (reflectionTemplateId?: string) => {
        if (!reflectionTemplateId) {
          return {
            error: new Error(
              "Unable to delete: No reflection template ID provided"
            ),
          };
        }
        try {
          await _deleteReflectionTemplate(reflectionTemplateId);
          return { data: null };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["ReflectionTemplate"],
    }),
    createReflection: builder.mutation({
      queryFn: async (reflectionTemplate: ReflectionTemplateClass) => {
        try {
          const reflection = await _createReflection(reflectionTemplate);
          return { data: reflection };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["Reflection"],
    }),
    updateReflection: builder.mutation({
      queryFn: async (reflectionForm: Partial<ReflectionFormType>) => {
        if (!reflectionForm.id) {
          return {
            error: new Error("Unable to update: No reflection ID provided"),
          };
        }
        try {
          const reflection = await _updateReflection(reflectionForm);
          return { data: reflection };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: (_result, _error, arg) => [
        { type: "Reflection", id: arg.id },
      ],
    }),
    fetchReflection: builder.query({
      queryFn: async (reflectionId?: string) => {
        if (!reflectionId) {
          return { error: new Error("No reflection ID provided") };
        }
        try {
          const reflection = await _fetchReflection(reflectionId);
          return { data: reflection };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: (result) =>
        result ? [{ type: "Reflection", id: result.id }] : ["Reflection"],
    }),
    fetchReflections: builder.query({
      queryFn: async () => {
        try {
          const reflections = await _fetchReflections();
          return { data: reflections };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map((reflection) => ({
                type: "Reflection" as const,
                id: reflection.id,
              })),
              "Reflection",
            ]
          : ["Reflection"],
    }),
    deleteReflection: builder.mutation({
      queryFn: async (reflectionId?: string) => {
        if (!reflectionId) {
          return {
            error: new Error("Unable to delete: No reflection ID provided"),
          };
        }
        try {
          await _deleteReflection(reflectionId);
          return { data: null };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: (_result, _error, arg) => [
        { type: "Reflection", id: arg },
        "Reflection",
      ],
    }),
  }),
});

export const {
  useCreateUpdateReflectionTemplateMutation,
  useFetchReflectionTemplateQuery,
  useFetchReflectionTemplatesQuery,
  useDeleteReflectionTemplateMutation,

  useCreateReflectionMutation,
  useUpdateReflectionMutation,
  useFetchReflectionQuery,
  useFetchReflectionsQuery,
  useDeleteReflectionMutation,
} = reflectionApi;

const _createUpdateReflectionTemplate = async (
  reflectionTemplateForm?: Partial<ReflectionTemplateFormType>
) => {
  let reflectionTemplate: ReflectionTemplateClass;
  if (reflectionTemplateForm) {
    reflectionTemplate = ReflectionTemplateClass.fromForm(
      reflectionTemplateForm
    );
  } else {
    reflectionTemplate = new ReflectionTemplateClass({});
  }
  reflectionTemplate.init(auth);

  return new Promise<ReflectionTemplateClass>((resolve, reject) => {
    setDoc(
      doc(db, Model.REFLECTION_TEMPLATES, reflectionTemplate.id),
      reflectionTemplate.toFirestore()
    )
      .then(() => {
        resolve(reflectionTemplate);
      })
      .catch((error) => {
        reject(error as Error);
      });
  });
};

const _fetchReflectionTemplate = async (reflectionTemplateId: string) => {
  return new Promise<ReflectionTemplateClass>((resolve, reject) => {
    getDoc(doc(db, Model.REFLECTION_TEMPLATES, reflectionTemplateId))
      .then((doc) => {
        if (doc.exists()) {
          resolve(
            ReflectionTemplateClass.fromFirestore(
              doc.data() as ReflectionTemplateFirestoreType
            )
          );
        } else {
          reject(new Error("Reflection template not found"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _fetchReflectionTemplates = async () => {
  return new Promise<ReflectionTemplateClass[]>((resolve, reject) => {
    const q = query(
      collection(db, Model.REFLECTION_TEMPLATES),
      orderBy(ReflectionTemplateField.TITLE, Order.ASCENDING)
    );

    getDocs(q)
      .then((querySnapshot) => {
        const templates: ReflectionTemplateClass[] = [];
        querySnapshot.forEach((doc) => {
          templates.push(
            ReflectionTemplateClass.fromFirestore(
              doc.data() as ReflectionTemplateFirestoreType
            )
          );
        });
        resolve(templates);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _deleteReflectionTemplate = async (reflectionTemplateId: string) => {
  return new Promise<void>((resolve, reject) => {
    deleteDoc(doc(db, Model.REFLECTION_TEMPLATES, reflectionTemplateId))
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error as Error);
      });
  });
};

const _createReflection = async (
  reflectionTemplate: ReflectionTemplateClass
) => {
  const reflection = new ReflectionClass(reflectionTemplate);
  reflection.init(auth);
  return new Promise<ReflectionClass>((resolve, reject) => {
    setDoc(doc(db, Model.REFLECTIONS, reflection.id), reflection.toFirestore())
      .then(() => {
        resolve(reflection);
      })
      .catch((error) => {
        reject(error as Error);
      });
  });
};

const _updateReflection = async (
  reflectionForm: Partial<ReflectionFormType>
) => {
  if (!reflectionForm.id) {
    throw new Error("Cannot update reflection without ID");
  }

  return new Promise<ReflectionClass>(async (resolve, reject) => {
    const reflection = ReflectionClass.fromForm(reflectionForm);
    reflection.init(auth);
    updateDoc(doc(db, Model.REFLECTIONS, reflection.id), {
      ...reflection.toFirestore(),
    })
      .then(() => {
        resolve(reflection);
      })
      .catch((error) => {
        reject(error as Error);
      });
  });
};

const _fetchReflection = async (reflectionId: string) => {
  return new Promise<ReflectionClass>((resolve, reject) => {
    getDoc(doc(db, Model.REFLECTIONS, reflectionId))
      .then((docSnap) => {
        if (docSnap.exists()) {
          const reflection = ReflectionClass.fromFirestore(
            docSnap.data() as ReflectionFirestoreType
          );
          resolve(reflection);
        } else {
          reject(new Error("Reflection not found"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _fetchReflections = async () => {
  // Only fetch the current user's reflections
  const currentUser = auth.currentUser;
  if (!currentUser) {
    throw new Error("User not authenticated");
  }

  return new Promise<ReflectionClass[]>((resolve, reject) => {
    const q = query(
      collection(db, Model.REFLECTIONS),
      where("uid", "==", currentUser.uid),
      orderBy(ReflectionField.CREATED_AT, Order.DESCENDING)
    );

    getDocs(q)
      .then((querySnapshot) => {
        const reflections: ReflectionClass[] = [];
        querySnapshot.forEach((doc) => {
          reflections.push(
            new ReflectionClass(doc.data() as ReflectionFirestoreType)
          );
        });
        resolve(reflections);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _deleteReflection = async (reflectionId: string) => {
  return new Promise<void>((resolve, reject) => {
    deleteDoc(doc(db, Model.REFLECTIONS, reflectionId))
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error as Error);
      });
  });
};
