import { auth, messaging } from "../config/firebase";
import { getToken, onMessage } from "firebase/messaging";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../features/snackbar";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  useFetchUserQuery,
  usePersistPushNotificationTokenMutation,
} from "../api/user";

type NotificationProviderProps = {
  children: React.ReactNode;
};

const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const [persistPushNotificationToken] =
    usePersistPushNotificationTokenMutation();
  const { data: user } = useFetchUserQuery(auth.currentUser?.uid ?? "");

  const isSupported = () =>
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window;

  useEffect(() => {
    if (
      isSupported() &&
      user &&
      Object.values(user.notifications).some((v) => v)
    ) {
      getToken(messaging, {
        vapidKey:
          "BE-0nnaXCXreGR5T9qnnOkjS6bGtxuJPP_LiJ8ZxcR8N2NKBM9xubhUe8DSLde9BUl7F1Xs8lw_pAaQGUwDEQ6s",
      })
        .then((token) => {
          persistPushNotificationToken(token);
        })
        .catch((error) => {
          console.error("Error getting push notification token:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (isSupported()) {
      onMessage(messaging, (payload) => {
        let action = undefined;
        const link = payload.fcmOptions?.link;
        const title = payload.notification?.title;

        if (link) {
          action = {
            label: "View",
            onClick: () => {
              window.open(link, "_self");
            },
          };
        }

        if (title) {
          dispatch(
            showSnackbar({
              message: `New notification: ${title}`,
              severity: Severity.INFO,
              action: action,
            })
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default NotificationProvider;
