import * as yup from "yup";
import { ReflectionClass } from "@beyondrealityapp/core/reflection/classes";
import { ReflectionFormType } from "@beyondrealityapp/core/reflection/types";
import { QuestionType } from "@beyondrealityapp/core/question/constants";

// Should generate initial values based on the reflection class for a Formik form
export const generateInitialValues = (
  reflection?: ReflectionClass
): ReflectionFormType => {
  if (!reflection) {
    return {
      id: "",
      uid: "",
      title: "",
      description: "",
      questions: [],
      reflectionTemplateId: "",
      image: "",
      imageVerticalOffset: 0,
      parent: "",
      children: {},
      metaData: {
        createdAt: new Date(),
        updatedAt: new Date(),
      },
    };
  }
  return {
    id: reflection.id || "",
    uid: reflection.uid || "",
    title: reflection.title || "",
    description: reflection.description || "",
    questions: reflection.questions.map((question) => {
      return question.toForm();
    }),
    reflectionTemplateId: reflection.reflectionTemplateId || "",
    image: reflection.image || "",
    imageVerticalOffset: reflection.imageVerticalOffset || 0,
    parent: reflection.parent || "",
    children: reflection.children || {},
    metaData: {
      createdAt: reflection.metaData?.createdAt || new Date(),
      updatedAt: reflection.metaData?.updatedAt || new Date(),
    },
  };
};

export const generateValidationSchema = (reflection: ReflectionClass) => {
  const questionSchemas = reflection.questions.reduce(
    (schemas, question, index) => {
      let schema;
      switch (question.type) {
        case QuestionType.SINGLE_LINE_TEXT:
          schema = yup.string();
          break;
        case QuestionType.MULTI_LINE_TEXT:
          schema = yup.string();
          break;
        case QuestionType.MULTIPLE_CHOICE:
          schema = yup.array().of(yup.string());
          break;
        case QuestionType.HAPPINESS_SCALE:
          schema = yup.string();
          break;
        default:
          schema = yup.mixed().notRequired();
      }

      const key = `question_${index}`;
      schemas[key] = schema;
      return schemas;
    },
    {} as Record<string, any>
  );

  return yup.object({
    title: yup.string(),
    description: yup.string(),
    questions: yup.array().of(yup.object(questionSchemas)),
  });
};
