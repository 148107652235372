import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { functions } from "../config/firebase";
import { HttpsCallable, httpsCallable } from "firebase/functions";
import PageContainer from "../components/layout/PageContainer";
import PersonalityTestsOverview from "../components/personality_tests/PersonalityTestsOverview";
import Typography from "@mui/material/Typography";
import UserTable from "../components/user/UserTable";

const AdminPanel = () => {
  const runMigration: HttpsCallable = httpsCallable(functions, "migration");
  const handleRunMigration = () => {
    console.log("Migration started");
    runMigration()
      .then((response) => {
        console.log("Migration finished", response);
      })
      .catch((error) => console.log(error));
  };
  return (
    <PageContainer>
      <Typography variant="h3">Admin panel</Typography>
      <Box sx={{ width: "80%", border: "1px solid LightGray", marginY: 2 }} />
      <Typography variant="h5" gutterBottom>
        Migration
      </Typography>
      <Button onClick={handleRunMigration} variant="contained" color="warning">
        Run migration
      </Button>
      <Box sx={{ width: "80%", border: "1px solid LightGray", marginY: 2 }} />
      <Typography variant="h5" gutterBottom>
        Personality Tests
      </Typography>
      <PersonalityTestsOverview isAdminPanel />
      <Box sx={{ width: "80%", border: "1px solid LightGray", marginY: 2 }} />
      <Typography variant="h5" gutterBottom>
        Users
      </Typography>
      <UserTable />
    </PageContainer>
  );
};

export default AdminPanel;
