import Box from "@mui/material/Box";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import ListCardController from "../../layout/list_card/ListCardController";
import Typography from "@mui/material/Typography";

type GoalCardViewProps = {
  onClickHandler: () => void;
  onSubgoalClickHandler: (event: React.MouseEvent, id: string) => void;
  highlightColor: string;
  image: string;
  content: string;
  description: string;
  progress: string;
  dates: string;
  themes: string[];
  children: {
    [id: string]: {
      name: string;
    };
  };
  updatedAt: string;
  compact?: boolean;
};

const GoalCardView: React.FC<GoalCardViewProps> = ({
  onClickHandler,
  onSubgoalClickHandler,
  highlightColor,
  image,
  content,
  description,
  progress,
  dates,
  themes,
  children,
  updatedAt,
  compact,
}) => {
  const bodyContent = (
    <Grid container direction="column" rowSpacing={1}>
      <Grid item>
        {description ? (
          <Typography variant="body2" color="text.secondary">
            {description.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </Typography>
        ) : null}
      </Grid>
      {dates ? (
        <Grid item>
          <Box
            sx={{ display: "flex", alignItems: "center" }}
            color="text.secondary"
          >
            <CalendarMonth color="inherit" fontSize="small" />
            <Typography variant="body2" marginLeft={1}>
              {dates}
            </Typography>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
  const footerContent = (
    <Grid
      item
      sx={{
        display: "flex",
        marginBottom: 2,
        marginLeft: 2,
        alignItems: "center",
      }}
    >
      {themes.length !== 0 ? (
        <Grid container columnSpacing={0.5} rowSpacing={0.5}>
          {themes.map((theme) => (
            <Grid item key={theme}>
              <Chip label={theme} size="small" color="primary" />
            </Grid>
          ))}
        </Grid>
      ) : null}
      {updatedAt ? (
        <Typography variant="caption">Last updated {updatedAt}</Typography>
      ) : null}
    </Grid>
  );
  return (
    <ListCardController
      onClickHandler={onClickHandler}
      onSubitemClickhandler={onSubgoalClickHandler}
      highlightColor={highlightColor}
      image={image}
      title={content}
      subtitle={compact ? "" : progress}
      bodyContent={bodyContent}
      footerContent={footerContent}
      subitems={children}
      compact={compact}
    />
  );
};

export default GoalCardView;
