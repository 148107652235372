import AddReflectionTemplateButton from "./AddReflectionTemplateButton";
import { Page } from "@beyondrealityapp/core/shared/constants";
import { useNavigate } from "react-router";

const AddReflectionTemplateButtonController = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/${Page.REFLECTIONS}/templates/-1`);
  };
  return <AddReflectionTemplateButton onClick={onClick} />;
};

export default AddReflectionTemplateButtonController;
