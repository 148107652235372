import BackButtonController from "../../layout/back_button/BackButtonController";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormContainer from "../../layout/FormContainer";
import { FormikState, FormikHelpers, FormikHandlers } from "formik";
import QuestionFactory from "../../questions/question/QuestionFactory";
import { ReflectionFormType } from "@beyondrealityapp/core/reflection/types";
import Typography from "@mui/material/Typography";
import SubmitDeleteActions from "../../layout/SubmitDeleteActions";

type ReflectionFormViewProps = {
  formik: FormikState<ReflectionFormType> &
    FormikHelpers<ReflectionFormType> &
    FormikHandlers;
  readonly: boolean;
  isLoading: boolean;
  submitting: boolean;
  deleting: boolean;
  handleDelete: () => void;
};

const ReflectionFormView: React.FC<ReflectionFormViewProps> = ({
  formik,
  readonly,
  isLoading,
  submitting,
  deleting,
  handleDelete,
}) => {
  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <BackButtonController />
      <FormContainer>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography variant="h5" gutterBottom textAlign="center">
            {formik.values.title}
          </Typography>
          <Typography variant="body2" gutterBottom textAlign="center">
            {formik.values.description}
          </Typography>

          {formik.values.questions.map((question, index) => (
            <QuestionFactory
              key={index}
              question={question}
              questionIndex={index}
              readonly={readonly}
              formik={formik}
            />
          ))}
          {!readonly && formik.values.id && (
            <SubmitDeleteActions
              deleting={deleting}
              submitting={submitting}
              allowDelete={true}
              deleteHandler={handleDelete}
            />
          )}
        </Box>
      </FormContainer>
    </>
  );
};

export default ReflectionFormView;
