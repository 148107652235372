import React from "react";
import NewRelfectionButton from "./NewReflectionButton";

type NewReflectionButtonProps = {
  onClick: () => void;
};

const NewReflectionButtonController: React.FC<NewReflectionButtonProps> = ({
  onClick,
}) => {
  return <NewRelfectionButton onClick={onClick} />;
};

export default NewReflectionButtonController;
