import ReflectionsTabView from "./ReflectionsTabView";
import { useState } from "react";

const ReflectionsTabController = () => {
  const [newReflectionModalOpen, setNewReflectionModalOpen] = useState(false);
  const onClickNewReflection = () => {
    setNewReflectionModalOpen(true);
  };
  const onClose = () => {
    setNewReflectionModalOpen(false);
  };
  return (
    <ReflectionsTabView
      onClickNewReflection={onClickNewReflection}
      onClose={onClose}
      newReflectionModalOpen={newReflectionModalOpen}
    />
  );
};

export default ReflectionsTabController;
