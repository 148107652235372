import BackButton from "./BackButton";
import { useNavigate } from "react-router";

const BackButtonController = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(-1);
  };

  const hasHistory = window.history.state && window.history.state.idx > 0;
  if (!hasHistory) {
    return null;
  }

  return <BackButton onClick={onClick} text="Back" />;
};

export default BackButtonController;
