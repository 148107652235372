import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import EditNoteIcon from "@mui/icons-material/EditNote";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HikingIcon from "@mui/icons-material/Hiking";
import { Link as RouterLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import { MenuItemType } from "@beyondrealityapp/core/shared/types";
import QuizIcon from "@mui/icons-material/Quiz";
import PeopleIcon from "@mui/icons-material/People";
import PublicIcon from "@mui/icons-material/Public";
import SettingsIcon from "@mui/icons-material/Settings";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { useLocation } from "react-router-dom";

const getIconComponent = (iconName: string) => {
  switch (iconName) {
    case "Favorite":
      return <FavoriteIcon />;
    case "Quiz":
      return <QuizIcon />;
    case "TipsAndUpdates":
      return <TipsAndUpdatesIcon />;
    case "Hiking":
      return <HikingIcon />;
    case "Public":
      return <PublicIcon />;
    case "CrisisAlert":
      return <CrisisAlertIcon />;
    case "Settings":
      return <SettingsIcon />;
    case "AdminPanelSettingsIcon":
      return <AdminPanelSettingsIcon />;
    case "EditNote":
      return <EditNoteIcon />;
    case "LogoutIcon":
      return <LogoutIcon />;
    case "PeopleIcon":
      return <PeopleIcon />;
    case "DynamicFormIcon":
      return <DynamicFormIcon />;
    default:
      return null;
  }
};

type MenuItemProps = {
  text: string;
  icon: string;
  path: string;
  onClose: () => void;
};

const MenuItem: React.FC<MenuItemProps> = ({
  text,
  icon,
  path,
  onClose,
}: MenuItemType) => {
  const location = useLocation();
  const iconComponent = getIconComponent(icon);
  return (
    <ListItem disablePadding>
      <ListItemButton
        component={RouterLink}
        to={path}
        onClick={onClose}
        selected={location.pathname.includes(path)}
      >
        <ListItemIcon>{iconComponent}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

export default MenuItem;
