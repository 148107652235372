import DraggableListView from "./DraggableListView";
import { OnDragEndResponder } from "@hello-pangea/dnd";
import React from "react";

type DraggableListControllerProps = {
  id: string;
  listItems: React.ReactNode[];
  onDragEnd: OnDragEndResponder;
};

const DraggableListController: React.FC<DraggableListControllerProps> = ({
  id,
  listItems,
  onDragEnd,
}) => {
  return (
    <DraggableListView
      id={id}
      listItems={listItems}
      onDragEnd={onDragEnd}
    />
  );
};

export default DraggableListController;
