import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { NotificationSettings } from "@beyondrealityapp/core/user/types";
import FormContainer from "../../layout/FormContainer";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import React from "react";

const notificationSettingsLabels = {
  productUpdates: "Product Updates",
  buddyCreatedGoal: "Buddy Created Goal",
  buddyCompletedGoal: "Buddy Completed Goal",
};

type NotificationSettingsViewProps = {
  notificationPermissionStatus:
    | "granted"
    | "denied"
    | "default"
    | "unsupported";
  notificationSettings: NotificationSettings;
  handleNotificationChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  disableHeader?: boolean;
};

const NotificationSettingsView: React.FC<NotificationSettingsViewProps> = ({
  notificationPermissionStatus,
  notificationSettings,
  handleNotificationChange,
  disableHeader,
}) => {
  return (
    <FormContainer>
      {!disableHeader && (
        <Typography variant="h6" paddingBottom={2}>
          Notification Settings
        </Typography>
      )}
      <Collapse in={notificationPermissionStatus === "denied"}>
        <Alert severity="warning">
          You have denied notification permission. Please enable it in your
          browser settings to receive notifications.
        </Alert>
      </Collapse>
      <Collapse in={notificationPermissionStatus === "default"}>
        <Alert severity="info">
          You need to allow notifications in your browser to enable
          notifications.
        </Alert>
      </Collapse>
      <Collapse in={notificationPermissionStatus === "unsupported"}>
        <Alert severity="error">
          Your browser does not support notifications. Please use a supported
          browser to enable notifications.
          <br />
          <br />
          Note: if you're on an iOS device, you need to add the app to your home
          screen to enable notifications.
        </Alert>
      </Collapse>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Notification</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Notify</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(notificationSettingsLabels).map(
            ([key, label], index) => (
              <TableRow key={index}>
                <TableCell>{label}</TableCell>
                <TableCell>
                  <Switch
                    checked={
                      notificationSettings[key as keyof NotificationSettings]
                    }
                    onChange={handleNotificationChange}
                    name={key}
                  />
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </FormContainer>
  );
};

export default NotificationSettingsView;
