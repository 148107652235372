import Box from "@mui/material/Box";
import NotificationSettingsController from "../../notifications/notification_settings/NotificationSettingsController";
import Typography from "@mui/material/Typography";

const WizardViewStepOne = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        marginTop: 2,
        width: "80%",
      }}
    >
      <Typography variant="h5">Enable notifications</Typography>
      <NotificationSettingsController disableHeader />
    </Box>
  );
};

export default WizardViewStepOne;
