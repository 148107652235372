import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import ExpandMore from "../ExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlagIcon from "@mui/icons-material/Flag";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React from "react";

type GoalCardViewProps = {
  title: string;
  subtitle: string;
  onClickHandler: () => void;
  onExpandClickHandler: () => void;
  onSubitemClickhandler?: (event: React.MouseEvent, id: string) => void;
  isXs: boolean;
  expanded: boolean;
  image?: string;
  bodyContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  subitems?: {
    [id: string]: {
      name: string;
    };
  };
  highlightColor?: string;
  compact?: boolean;
};

const ListCardView: React.FC<GoalCardViewProps> = ({
  onClickHandler,
  onExpandClickHandler,
  onSubitemClickhandler,
  isXs,
  expanded,
  image,
  title,
  subtitle,
  bodyContent,
  footerContent,
  subitems,
  highlightColor,
  compact,
}) => {
  return (
    <>
      <Card
        onClick={onClickHandler}
        sx={{
          width: "100%",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            minHeight: compact ? "40px" : "80px",
          }}
        >
          {highlightColor ? (
            <Box
              sx={{
                width: "4px",
                backgroundColor: highlightColor,
              }}
            />
          ) : null}
          {!compact && image ? (
            <CardMedia
              sx={{
                width: isXs ? "25%" : "20%",
              }}
              image={image}
            />
          ) : null}
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              <Tooltip title={title} placement="top">
                <div>
                  <CardHeader
                    titleTypographyProps={{
                      variant: "body2",
                      fontWeight: 700,
                    }}
                    title={title}
                    sx={{
                      height: "100%",
                      "& .MuiCardHeader-title": {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        wordBreak: "break-word",
                      },
                    }}
                    subheader={!compact ? subtitle : null}
                    subheaderTypographyProps={{
                      variant: "body2",
                      color: "text.secondary",
                    }}
                  />
                </div>
              </Tooltip>
              {bodyContent || footerContent ? (
                <ExpandMore
                  expand={expanded}
                  onClick={(event) => {
                    event.stopPropagation();
                    onExpandClickHandler();
                  }}
                  aria-expanded={expanded}
                  aria-label="show more"
                  sx={{
                    marginRight: 1,
                  }}
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              ) : null}
            </Box>
          </Box>
        </Box>
        {subitems ? (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>{bodyContent}</CardContent>
            {footerContent}
            {Object.keys(subitems).length !== 0 ? (
              <Box>
                <Divider />
                <Box
                  onClick={(event) => event.stopPropagation()}
                  sx={{
                    "&:hover": {
                      cursor: "initial",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      marginLeft: 2,
                      marginTop: 2,
                    }}
                  >
                    <FlagIcon fontSize="small" />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontWeight: 600,
                        marginLeft: 1,
                      }}
                    >
                      Subgoals:
                    </Typography>
                  </Box>
                  <List
                    sx={{
                      marginLeft: 1,
                    }}
                    dense
                  >
                    {Object.entries(subitems).map(([id, subitem]) => (
                      <ListItem
                        key={id}
                        onClick={
                          onSubitemClickhandler
                            ? (event) => onSubitemClickhandler(event, id)
                            : undefined
                        }
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <ListItemText primary={subitem.name} />
                        <IconButton size="small">
                          <LaunchIcon fontSize="small" />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            ) : null}
          </Collapse>
        ) : null}
      </Card>
    </>
  );
};

export default ListCardView;
