import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardImageHeaderController from "../../image/card_image_header/CardImageHeaderController";
import ThemeModalController from "../theme_modal/ThemeModalController";
import { ThemeClass } from "@beyondrealityapp/core/theme/classes";
import Typography from "@mui/material/Typography";

export type ThemeCardViewProps = {
  theme: ThemeClass;
  modalIsOpen: boolean;
  onCloseHandler: () => void;
  onClickHandler: () => void;
  readonly?: boolean;
};

const ThemeCardView: React.FC<ThemeCardViewProps> = ({
  theme,
  modalIsOpen,
  onClickHandler,
  onCloseHandler,
  readonly,
}) => {
  return (
    <>
      <Card
        sx={{
          width: "100%",
          height: "100%",
          cursor: readonly ? "default" : "pointer",
        }}
        onClick={readonly ? undefined : onClickHandler}
      >
        {theme.image && (
          <CardImageHeaderController
            imageUrl={theme.image}
            verticalOffset={theme.imageVerticalOffset ?? 50}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingX: 2,
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
            gutterBottom
          >
            {theme.name}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {theme.description.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </Typography>
        </Box>
      </Card>
      {modalIsOpen && (
        <ThemeModalController
          theme={theme}
          isOpen={modalIsOpen}
          onClose={onCloseHandler}
        />
      )}
    </>
  );
};

export default ThemeCardView;
