import { auth } from "../../../config/firebase";
import { journalEntryApi } from "../../../api/journal";
import { goalsApi } from "../../../api/goals";
import { reviewApi } from "../../../api/review";
import { themesApi } from "../../../api/theme";
import { userApi } from "../../../api/user";
import { Page, Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import { signOut } from "firebase/auth";
import UserProfileMenuView from "./UserProfileMenuView";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

type UserProfileMenuControllerProps = {
  open: boolean;
  onClose: () => void;
  anchorEl: null | HTMLElement;
};

const UserProfileMenuController: React.FC<UserProfileMenuControllerProps> = ({
  open,
  onClose,
  anchorEl,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onProfileClick = () => {
    navigate(Page.USER_PROFILE);
    onClose();
  };

  const onNotificationSettingsClick = () => {
    navigate(Page.NOTIFICATIONS);
    onClose();
  };

  const logoutHandler = () => {
    signOut(auth)
      .then(() => {
        dispatch(
          showSnackbar({
            message: "Successfully logged out",
            severity: Severity.SUCCESS,
          })
        );
        navigate(Page.SIGN_IN);
        dispatch(journalEntryApi.util.resetApiState());
        dispatch(goalsApi.util.resetApiState());
        dispatch(reviewApi.util.resetApiState());
        dispatch(themesApi.util.resetApiState());
        dispatch(userApi.util.resetApiState());
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message:
              error instanceof Error
                ? error.message
                : "An error occurred while logging out",
            severity: Severity.ERROR,
          })
        );
      });
  };

  return (
    <UserProfileMenuView
      open={open}
      onClose={onClose}
      onProfileClick={onProfileClick}
      onNotificationSettingsClick={onNotificationSettingsClick}
      onLogout={logoutHandler}
      anchorEl={anchorEl}
    />
  );
};

export default UserProfileMenuController;
