import Box from "@mui/material/Box";
import { FormikState, FormikHelpers, FormikHandlers } from "formik";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getIn } from "formik";
import { QuestionFormType } from "@beyondrealityapp/core/question/types";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { ReflectionFormType } from "@beyondrealityapp/core/reflection/types";

interface QuestionFactoryProps {
  question: QuestionFormType;
  questionIndex: number;
  readonly?: boolean;
  formik: FormikState<ReflectionFormType> &
    FormikHelpers<ReflectionFormType> &
    FormikHandlers;
}

const QuestionFactory: React.FC<QuestionFactoryProps> = ({
  question,
  questionIndex,
  readonly = false,
  formik,
}) => {
  const fieldName = `questions[${questionIndex}].value`;
  const currentValue = getIn(formik.values, fieldName) || "";
  const touchedField = getIn(formik.touched, fieldName);
  const errorMessage = getIn(formik.errors, fieldName);
  const hasError = touchedField && errorMessage;

  const renderQuestion = () => {
    switch (question.type) {
      case "single_line_text":
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" textAlign="center">
              {question.name}
            </Typography>
            <TextField
              fullWidth
              id={fieldName}
              name={fieldName}
              value={currentValue}
              placeholder="Enter your answer here..."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(hasError)}
              helperText={hasError ? errorMessage : ""}
              disabled={readonly}
              margin="normal"
            />
          </Box>
        );

      case "multi_line_text":
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" textAlign="center">
              {question.name}
            </Typography>
            <TextField
              fullWidth
              id={fieldName}
              name={fieldName}
              placeholder="Enter your answer here..."
              value={currentValue}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(hasError)}
              helperText={hasError ? errorMessage : ""}
              disabled={readonly}
              margin="normal"
              multiline
              rows={4}
            />
          </Box>
        );

      case "multiple_choice":
        if (!question.choices || question.choices.length === 0) {
          return <Typography color="error">No choices available</Typography>;
        }

        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" textAlign="center">
              {question.name}
            </Typography>
            <FormControl
              component="fieldset"
              margin="normal"
              fullWidth
              disabled={readonly}
            >
              <RadioGroup
                name={fieldName}
                value={currentValue}
                onChange={formik.handleChange}
              >
                {question.choices.map((choice, idx) => (
                  <FormControlLabel
                    key={idx}
                    value={choice}
                    control={<Radio />}
                    label={choice}
                  />
                ))}
              </RadioGroup>
              {hasError && (
                <Typography color="error" variant="caption">
                  {errorMessage}
                </Typography>
              )}
            </FormControl>
          </Box>
        );

      case "happiness_scale":
        if (!question.choices || question.choices.length === 0) {
          return (
            <Typography color="error">No happiness scale available</Typography>
          );
        }

        return (
          <Box sx={{ mt: 2, mb: 2 }}>
            <FormControl component="fieldset" fullWidth disabled={readonly}>
              <Typography variant="h6" textAlign="center">
                {question.name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  flexWrap: "wrap",
                }}
              >
                {question.choices.map((emoji, idx) => (
                  <Box
                    key={idx}
                    onClick={() =>
                      !readonly && formik.setFieldValue(fieldName, emoji)
                    }
                    sx={{
                      fontSize: "1.5rem",
                      cursor: readonly ? "default" : "pointer",
                      padding: 1,
                      borderRadius: "50%",
                      backgroundColor:
                        currentValue === emoji
                          ? "rgba(0, 0, 0, 0.1)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor: readonly
                          ? "transparent"
                          : "rgba(0, 0, 0, 0.05)",
                      },
                    }}
                  >
                    {emoji}
                  </Box>
                ))}
              </Box>
              {hasError && (
                <Typography color="error" variant="caption">
                  {errorMessage}
                </Typography>
              )}
            </FormControl>
          </Box>
        );

      default:
        return (
          <Typography color="error">
            Unknown question type: {question.type}
          </Typography>
        );
    }
  };

  return <Box sx={{ mb: 2 }}>{renderQuestion()}</Box>;
};

export default QuestionFactory;
