import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "@firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "@firebase/functions";
import { getMessaging } from "firebase/messaging";
import { getStorage, connectStorageEmulator } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCyA-H_VNy8nNu6AuWTjDKSbsvv-HkNOX4",
  authDomain: "beyond-reality-b4944.firebaseapp.com",
  projectId: "beyond-reality-b4944",
  storageBucket: "beyond-reality-b4944.appspot.com",
  messagingSenderId: "360712534263",
  appId: "1:360712534263:web:17635a0aa67062d0293bf2",
  measurementId: "G-8VX882357Z",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const messaging = getMessaging(app);
export const storage = getStorage(app);
export const functionRegion = "europe-west1";
export const functions = getFunctions(app, functionRegion);

// eslint-disable-next-line no-restricted-globals
if (location.hostname === "localhost") {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectStorageEmulator(storage, "localhost", 9199);
}
