import PageContainer from "../components/layout/PageContainer";
import ReflectionTabsController from "../components/reflections/reflection_tabs/ReflectionTabsController";

const Reflections = () => {
  return (
    <PageContainer>
      <ReflectionTabsController />
    </PageContainer>
  );
};

export default Reflections;
