import { auth, messaging } from "../../../config/firebase";
import { getToken } from "firebase/messaging";
import NotificationSettingsView from "./NotificationSettingsView";
import { NotificationSettings } from "@beyondrealityapp/core/user/types";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import { useDispatch } from "react-redux";
import { useFetchUserQuery } from "../../../api/user";
import {
  useUpdateNotificationSettingsMutation,
  usePersistPushNotificationTokenMutation,
} from "../../../api/user";
import { useEffect, useState } from "react";

type NotificationSettingsControllerProps = {
  disableHeader?: boolean;
};

const NotificationSettingsController: React.FC<
  NotificationSettingsControllerProps
> = ({ disableHeader }) => {
  const {
    data: user,
    error,
    isError,
  } = useFetchUserQuery(auth.currentUser?.uid ?? "", {
    skip: !auth.currentUser?.uid,
  });
  const dispatch = useDispatch();
  const [updateNotificationSettings] = useUpdateNotificationSettingsMutation();
  const [persistPushNotificationToken] =
    usePersistPushNotificationTokenMutation();
  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettings>({
      productUpdates: false,
      buddyCreatedGoal: false,
      buddyCompletedGoal: false,
    });
  const isSupported = () =>
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window;

  useEffect(() => {
    if (
      isSupported() &&
      user?.notifications &&
      Object.values(user.notifications).some((value) => value) &&
      Notification.permission === "default"
    ) {
      getToken(messaging, {
        vapidKey:
          "BE-0nnaXCXreGR5T9qnnOkjS6bGtxuJPP_LiJ8ZxcR8N2NKBM9xubhUe8DSLde9BUl7F1Xs8lw_pAaQGUwDEQ6s",
      })
        .then((currentToken) => {
          persistPushNotificationToken(currentToken)
            .unwrap()
            .catch((error: unknown) => {
              dispatch(
                showSnackbar({
                  severity: Severity.ERROR,
                  message:
                    error instanceof Error
                      ? error.message
                      : "An error occurred while persisting the notification token",
                })
              );
            });
        })
        .catch((error) => {
          dispatch(
            showSnackbar({
              severity: Severity.ERROR,
              message:
                error instanceof Error
                  ? error.message
                  : "An error occurred while fetching the notification token",
            })
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (isError) {
      dispatch(
        showSnackbar({
          severity: Severity.ERROR,
          message:
            error instanceof Error
              ? error.message
              : "An error occurred while fetching user data",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, error]);

  useEffect(() => {
    if (user) {
      setNotificationSettings(user.notifications);
    }
  }, [user]);

  const handleNotificationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedNotificationSettings = {
      ...notificationSettings,
      [event.target.name as keyof NotificationSettings]: event.target.checked,
    };
    updateNotificationSettings(updatedNotificationSettings)
      .unwrap()
      .then(() => {
        setNotificationSettings(updatedNotificationSettings);
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            severity: Severity.ERROR,
            message:
              error instanceof Error
                ? error.message
                : "An error occurred while updating notification settings",
          })
        );
      });
  };

  return (
    <NotificationSettingsView
      notificationPermissionStatus={
        isSupported() ? Notification.permission : "unsupported"
      }
      notificationSettings={notificationSettings}
      handleNotificationChange={handleNotificationChange}
      disableHeader={disableHeader}
    />
  );
};

export default NotificationSettingsController;
