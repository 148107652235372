import AddImageModalController from "../../image/add_image_modal/AddImageModalController";
import { array, object, string } from "yup";
import { auth } from "../../../config/firebase";
import {
  Empty,
  ErrorMessage,
  Page,
} from "@beyondrealityapp/core/shared/constants";
import { OnDragEndResponder } from "@hello-pangea/dnd";
import { ReflectionTemplateFormType } from "@beyondrealityapp/core/reflection/template/types";
import ReflectionTemplateFormView from "./ReflectionTemplateFormView";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import {
  useCreateUpdateReflectionTemplateMutation,
  useFetchReflectionTemplateQuery,
  useDeleteReflectionTemplateMutation,
} from "../../../api/reflection";
import { useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { SelectChangeEvent } from "@mui/material";

const ReflectionTemplateFormController = () => {
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [addImageModelOpen, setAddImageModelOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createUpdateReflectionTemplate] =
    useCreateUpdateReflectionTemplateMutation();
  const [deleteReflectionTemplate] = useDeleteReflectionTemplateMutation();
  const { reflectionTemplateId } = useParams();

  const {
    data: reflectionTemplate,
    isError,
    error,
    isLoading,
  } = useFetchReflectionTemplateQuery(reflectionTemplateId, {
    skip: reflectionTemplateId === "-1" || !reflectionTemplateId,
  });

  const reflectionTemplateSchema = useMemo(() => {
    return object().shape({
      title: string().required("Title is required"),
      description: string().required("Description is required"),
      questions: array()
        .max(20, "A maximum of 20 questions is allowed")
        .min(1, "Please add at least 1 question")
        .of(
          object().shape({
            name: string().required("Question is required"),
            type: string()
              .required("Type is required")
              .oneOf([
                "single_line_text",
                "multi_line_text",
                "multiple_choice",
                "happiness_scale",
              ]),
            choices: array().when("type", {
              is: (val: string) =>
                val === "multiple_choice" || val === "happiness_scale",
              then: (schema) =>
                schema
                  .min(2, "Please add at least 2 choices")
                  .max(10, "Maximum of 10 choices allowed"),
              otherwise: (schema) => schema.notRequired(),
            }),
          })
        ),
    });
  }, []);

  const initialValues: ReflectionTemplateFormType = {
    id: Empty.STRING,
    uid: Empty.STRING,
    title: Empty.STRING,
    description: Empty.STRING,
    questions: [],
  };

  const onSubmit = async (values: ReflectionTemplateFormType) => {
    setSubmitting(true);
    createUpdateReflectionTemplate(values)
      .then((response) => {
        if ("data" in response && response.data) {
          dispatch(
            showSnackbar({
              message: "Reflection template saved successfully",
              severity: Severity.SUCCESS,
            })
          );
          navigate(`/${Page.REFLECTION_TEMPLATES}/${response.data.id}`, {
            replace: true,
          });
        } else if ("error" in response) {
          const error = response.error as Error;
          dispatch(
            showSnackbar({
              message: error.message,
              severity: Severity.ERROR,
            })
          );
        } else {
          dispatch(
            showSnackbar({
              message: ErrorMessage.UNKNOWN_ERROR,
              severity: Severity.ERROR,
            })
          );
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleDelete = async () => {
    setDeleting(true);
    deleteReflectionTemplate(reflectionTemplateId)
      .then(() => {
        dispatch(
          showSnackbar({
            message: "Reflection template deleted successfully",
            severity: Severity.SUCCESS,
          })
        );
        navigate(`/${Page.REFLECTIONS}?tab=reflection-templates`, {
          replace: true,
        });
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: error.message,
            severity: Severity.ERROR,
          })
        );
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const onAddImage = () => {
    setAddImageModelOpen(true);
  };

  const handleImageModelClose = () => {
    setAddImageModelOpen(false);
  };

  const onSetImage = (image: string) => {
    formik.setFieldValue("image", image, false);
  };

  const addQuestion = () => {
    const newQuestions = [
      ...formik.values.questions,
      { name: "", type: "single_line_text" },
    ];
    formik.setFieldValue("questions", newQuestions, false);
  };

  const onDeleteQuestion = (questionIndex: number) => {
    const questions = formik.values.questions;
    questions.splice(questionIndex, 1);
    formik.setFieldValue("questions", questions);
  };

  const onChangeType = (event: SelectChangeEvent<string[]>, index: number) => {
    if (event.target.value === "multiple_choice") {
      formik.setFieldValue(`questions[${index}].choices`, ["", ""]);
    }
    if (event.target.value === "happiness_scale") {
      formik.setFieldValue(`questions[${index}].choices`, [
        "😖",
        "😞",
        "😐",
        "😊",
        "😄",
        "😁",
      ]);
    }
    formik.setFieldValue(`questions[${index}].type`, event.target.value);
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) return;
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const questions = [...formik.values.questions];
    const [reOrderedQuestion] = questions.splice(startIndex, 1);
    questions.splice(endIndex, 0, reOrderedQuestion);
    formik.setFieldValue("questions", questions);
  };

  const onAddChoice = (index: number, value: string) => {
    const questions = [...formik.values.questions];
    questions[index].choices = [...(questions[index].choices || []), value];
    formik.setFieldValue("questions", questions);
  };

  const onDeleteChoice = (index: number, choiceIndex: number) => {
    const questions = [...formik.values.questions];
    questions[index].choices?.splice(choiceIndex, 1);
    formik.setFieldValue("questions", questions);
  };

  const onChangeChoices = (
    index: number,
    choiceIndex: number,
    value: string
  ) => {
    const questions = [...formik.values.questions];
    questions[index].choices![choiceIndex] = value;
    formik.setFieldValue("questions", questions);
  };

  const onChoiceClickUp = (index: number, choiceIndex: number) => {
    const questions = [...formik.values.questions];
    const temp = questions[index].choices![choiceIndex];
    questions[index].choices![choiceIndex] =
      questions[index].choices![choiceIndex - 1];
    questions[index].choices![choiceIndex - 1] = temp;
    formik.setFieldValue("questions", questions);
  };

  const onChoiceClickDown = (index: number, choiceIndex: number) => {
    const questions = [...formik.values.questions];
    const temp = questions[index].choices![choiceIndex];
    questions[index].choices![choiceIndex] =
      questions[index].choices![choiceIndex + 1];
    questions[index].choices![choiceIndex + 1] = temp;
    formik.setFieldValue("questions", questions);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: reflectionTemplateSchema,
    onSubmit,
  });

  const isReadOnly = useMemo(() => {
    return (
      formik.values.uid !== auth.currentUser?.uid &&
      reflectionTemplateId !== "-1"
    );
  }, [formik.values.uid, reflectionTemplateId]);

  useEffect(() => {
    if (formik.values.questions.length > 0) {
      formik.validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.questions]);

  useEffect(() => {
    if (reflectionTemplate) {
      formik.setValues(
        reflectionTemplate.toForm() as ReflectionTemplateFormType
      );
    }
    if (isError) {
      error instanceof Error &&
        dispatch(
          showSnackbar({
            message: error?.message || ErrorMessage.UNKNOWN_ERROR,
            severity: Severity.ERROR,
          })
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reflectionTemplate, isError, error]);

  return (
    <>
      <ReflectionTemplateFormView
        formik={formik}
        onAddImage={onAddImage}
        addQuestion={addQuestion}
        onDeleteQuestion={onDeleteQuestion}
        onChangeType={onChangeType}
        onDragEnd={onDragEnd}
        readonly={isReadOnly}
        isLoading={isLoading}
        submitting={submitting}
        deleting={deleting}
        handleDelete={handleDelete}
        onAddChoice={onAddChoice}
        onDeleteChoice={onDeleteChoice}
        onChangeChoices={onChangeChoices}
        onChoiceClickUp={onChoiceClickUp}
        onChoiceClickDown={onChoiceClickDown}
      />
      {addImageModelOpen && (
        <AddImageModalController
          open={addImageModelOpen}
          onClose={handleImageModelClose}
          setImageHandler={onSetImage}
        />
      )}
    </>
  );
};

export default ReflectionTemplateFormController;
