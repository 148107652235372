import { auth } from "../config/firebase";
import { hideSnackbar, showSnackbar } from "../features/snackbar";
import { Page } from "@beyondrealityapp/core/shared/constants";
import React, { useEffect } from "react";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { useDispatch } from "react-redux";
import { useFetchUserQuery } from "../api/user";
import { useLocation, useNavigate } from "react-router";

type UserProviderProps = {
  children: React.ReactNode;
};

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: user } = useFetchUserQuery(auth.currentUser?.uid ?? "");

  const logOut = async () => {
    dispatch(hideSnackbar());
    auth
      .signOut()
      .then(() => {
        navigate(Page.SIGN_IN);
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            severity: Severity.ERROR,
            message: error.message,
          })
        );
      });
  };

  useEffect(() => {
    const checkBuddy = async () => {
      if (user && user.sharedWithMe.length > 0) {
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        const customClaimsBuddies = idTokenResult?.claims.buddies as
          | string[]
          | undefined;
        const hasAllBuddiesInCustomClaims = user.sharedWithMe.every(
          (buddy) => customClaimsBuddies?.includes(buddy.uid) ?? false
        );

        if (!customClaimsBuddies || !hasAllBuddiesInCustomClaims) {
          dispatch(
            showSnackbar({
              severity: Severity.WARNING,
              message:
                "You've been added as a buddy. Please log out and log back in to see your buddy's goals.",
              action: {
                label: "Log out",
                onClick: logOut,
              },
            })
          );
        }
      }
    };
    checkBuddy();

    if (user && user.name === "" && !location.state?.fromOnboarding) {
      navigate("/onboarding");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return <>{children}</>;
};

export default UserProvider;
