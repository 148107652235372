import { auth } from "../../../config/firebase";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import UserProfileAvatarView from "./UserProfileAvatarView";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useFetchUserQuery } from "../../../api/user";

const UserProfileAvatarController: React.FC = () => {
  const [userProfileMenuOpen, setUserProfileMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onUserProfileClick = () => {
    setUserProfileMenuOpen(true);
  };
  const onBackdropClick = () => {
    setUserProfileMenuOpen(false);
  };
  const {
    data: user,
    error: userError,
    isError: isErrorUser,
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useFetchUserQuery(auth.currentUser?.uid ?? "");

  useEffect(() => {
    if (auth.currentUser && isErrorUser) {
      dispatch(
        showSnackbar({
          severity: Severity.ERROR,
          message:
            userError instanceof Error
              ? userError.message
              : "An error occurred while loading your user",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorUser, user]);

  return (
    <UserProfileAvatarView
      user={user}
      onUserProfileClick={onUserProfileClick}
      userProfileMenuOpen={userProfileMenuOpen}
      onBackdropClick={onBackdropClick}
      refetchUser={refetchUser}
      isUserError={isErrorUser}
      isUserLoading={isLoadingUser}
      buttonRef={buttonRef}
    />
  );
};

export default UserProfileAvatarController;
