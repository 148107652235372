import { configureStore } from "@reduxjs/toolkit";
import { goalsApi } from "../api/goals";
import { journalEntryApi } from "../api/journal";
import { reflectionApi } from "../api/reflection";
import { reviewApi } from "../api/review";
import { themesApi } from "../api/theme";
import { userApi } from "../api/user";
import snackbarReducer from "../features/snackbar";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: {
    [goalsApi.reducerPath]: goalsApi.reducer,
    [journalEntryApi.reducerPath]: journalEntryApi.reducer,
    [reflectionApi.reducerPath]: reflectionApi.reducer,
    [reviewApi.reducerPath]: reviewApi.reducer,
    [themesApi.reducerPath]: themesApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    snackbar: snackbarReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(goalsApi.middleware)
      .concat(journalEntryApi.middleware)
      .concat(reflectionApi.middleware)
      .concat(reviewApi.middleware)
      .concat(themesApi.middleware)
      .concat(userApi.middleware);
  },
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
