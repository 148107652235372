import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MobileStepper from "@mui/material/MobileStepper";
import WizardViewStepZero from "./WizardViewStepZero";
import WizardViewStepOne from "./WizardViewStepOne";
import WizardViewStepTwo from "./WizardViewStepTwo";

type OnboardingWizardViewProps = {
  activeStep: number;
  steps: string[];
  onStartClick: () => void;
  onBackClick: () => void;
  onNextClick: () => void;
  onSaveSuccess: () => void;
  savingUserProfile: boolean;
  setSavingUserProfile: (saving: boolean) => void;
  backgroundColor: string;
};

const OnboardingWizardView: React.FC<OnboardingWizardViewProps> = ({
  activeStep,
  steps,
  onStartClick,
  onBackClick,
  onNextClick,
  onSaveSuccess,
  savingUserProfile,
  setSavingUserProfile,
  backgroundColor,
}) => {
  const setNextButton = () => {
    switch (activeStep) {
      case 0:
        return <Box sx={{ height: "36px", width: "64px" }} />;
      case 2:
        return (
          <Button size="small" type="submit" form="user-profile-form">
            {savingUserProfile ? <CircularProgress size={24} /> : "Save"}
          </Button>
        );
      default:
        return (
          <Button size="small" onClick={onNextClick}>
            Next
          </Button>
        );
    }
  };
  const setBackButton = () => {
    switch (activeStep) {
      case 0:
        return <Box sx={{ height: "36px", width: "64px" }} />;
      default:
        return (
          <Button size="small" onClick={onBackClick}>
            Back
          </Button>
        );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "calc(100vh - 96px)",
        width: "100%",
        backgroundColor: backgroundColor,
        borderRadius: 8,
        padding: 4,
      }}
    >
      {activeStep === 0 && <WizardViewStepZero onStartClick={onStartClick} />}
      {activeStep === 1 && <WizardViewStepOne />}
      {activeStep === 2 && (
        <WizardViewStepTwo
          onSaveSuccess={onSaveSuccess}
          setSavingUserProfile={setSavingUserProfile}
        />
      )}
      <MobileStepper
        variant="dots"
        steps={steps.length}
        position="static"
        activeStep={activeStep}
        sx={{
          width: "100%",
          backgroundColor: "transparent",
        }}
        nextButton={setNextButton()}
        backButton={setBackButton()}
      />
    </Box>
  );
};

export default OnboardingWizardView;
