import PageContainer from "../components/layout/PageContainer";
import FormContainer from "../components/layout/FormContainer";
import Typography from "@mui/material/Typography";
import UserProfileFormController from "../components/user/user_profile_form/UserProfileFormController";

const UserProfile = () => {
  return (
    <PageContainer>
      <FormContainer>
        <Typography variant="h6" paddingBottom={2}>
          User Profile
        </Typography>
        <UserProfileFormController />
      </FormContainer>
    </PageContainer>
  );
};

export default UserProfile;
