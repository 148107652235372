import { Page } from "@beyondrealityapp/core/shared/constants";
import ReflectionTemplatesListView from "./ReflectionTemplatesListView";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import { useDispatch } from "react-redux";
import { useFetchReflectionTemplatesQuery } from "../../../api/reflection";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ReflectionTemplatesListController = () => {
  const {
    data: reflectionTemplates,
    isError,
    isLoading,
    error,
  } = useFetchReflectionTemplatesQuery(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (reflectionTemplateId: string) => {
    navigate(`/${Page.REFLECTION_TEMPLATES}/${reflectionTemplateId}`);
  };

  useEffect(() => {
    if (isError) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "An error occurred while fetching the reflection templates";
      dispatch(
        showSnackbar({
          message: errorMessage,
          severity: Severity.ERROR,
        })
      );
    }
  }, [isError, error, dispatch]);
  return (
    <ReflectionTemplatesListView
      templates={reflectionTemplates || []}
      onClick={handleClick}
      isLoading={isLoading}
    />
  );
};

export default ReflectionTemplatesListController;
