import React, { useState, useCallback } from "react";
import ListCardView from "./ListCardView";
import useBreakpoints from "../../../hooks/useBreakpoints";

type ListCardControllerProps = {
  title: string;
  subtitle: string;
  onClickHandler: () => void;
  image?: string;
  onSubitemClickhandler?: (event: React.MouseEvent, id: string) => void;
  highlightColor?: string;
  bodyContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  subitems?: {
    [id: string]: {
      name: string;
    };
  };
  compact?: boolean;
};

const ListCardController: React.FC<ListCardControllerProps> = React.memo(
  ({
    onClickHandler,
    onSubitemClickhandler,
    highlightColor,
    image,
    title,
    subtitle,
    bodyContent,
    footerContent,
    subitems,
    compact,
  }) => {
    const { isXs } = useBreakpoints();
    const [expanded, setExpanded] = useState<boolean>(false);

    // Memoize the handler to prevent it from being recreated on each render
    const onExpandClickHandler = useCallback(() => {
      setExpanded((prevState) => !prevState);
    }, []);

    return (
      <ListCardView
        onClickHandler={onClickHandler}
        onExpandClickHandler={onExpandClickHandler}
        onSubitemClickhandler={onSubitemClickhandler}
        isXs={isXs}
        expanded={expanded}
        highlightColor={highlightColor}
        image={image}
        title={title}
        subtitle={subtitle}
        bodyContent={bodyContent}
        footerContent={footerContent}
        subitems={subitems}
        compact={compact}
      />
    );
  }
);

export default ListCardController;
