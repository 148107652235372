import AccountabilityBuddies from "./pages/AccountabilityBuddies";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddPersonalityTestModal from "./components/personality_tests/AddPersonalityTestModal";
import AdminPanel from "./pages/AdminPanel";
import AwaitingVerification from "./pages/AwaitingVerification";
import Box from "@mui/material/Box";
import Buddy from "./pages/Buddy";
import { ThemeProvider } from "@mui/material/styles";
import CoreValues from "./pages/CoreValues";
import CssBaseline from "@mui/material/CssBaseline";
import GenericSnackbarController from "./components/generic/generic_snackbar/GenericSnackbarController";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import GoalDetails from "./pages/GoalDetails";
import Goals from "./pages/Goals";
import Home from "./pages/Home";
import Journal from "./pages/Journal";
import JournalEntry from "./pages/JournalEntry";
import LoadingSpinner from "./components/layout/LoadingSpinner";
import { LocalizationProvider } from "@mui/x-date-pickers";
import NavBarController from "./components/nav_bar/NavBarController";
import Notifications from "./pages/Notifications";
import NotificationProvider from "./providers/NotificationProvider";
import Onboarding from "./pages/Onboarding";
import OnlineProvider from "./providers/OnlineProvider";
import { Page } from "@beyondrealityapp/core/shared/constants";
import PersonalityTests from "./pages/PersonalityTests";
import { Provider } from "react-redux";
import ProtectedRoute from "./components/authentication/ProtectedRoute";
import RedirectSignInUpRoute from "./components/authentication/RedirectSignInRoute";
import Reflections from "./pages/Reflections";
import ReflectionDetails from "./pages/ReflectionDetails";
import ReflectionTemplateDetails from "./pages/ReflectionTemplateDetails";
import Reviews from "./pages/Reviews";
import ReviewDetails from "./pages/ReviewDetails";
import { Routes, Route } from "react-router-dom";
import Settings from "./pages/Settings";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import { store } from "./config/store";
import theme from "./config/theme";
import Themes from "./pages/Themes";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import UserProvider from "./providers/UserProvider";
import UserProfile from "./pages/UserProfile";
import "@mdxeditor/editor/style.css";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const previousLocation = location.state?.previousLocation;

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, () => {
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <LoadingSpinner />;
      </Box>
    );
  }
  return (
    <Provider store={store}>
      <NotificationProvider>
        <UserProvider>
          <OnlineProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider theme={theme}>
                <CssBaseline>
                  <Routes location={previousLocation || location}>
                    <Route
                      path={Page.AWAITING_VERIFICATION}
                      element={<AwaitingVerification />}
                    />
                    <Route
                      element={
                        <ProtectedRoute allowedRoles={["admin", "user"]} />
                      }
                    >
                      <Route path={Page.ONBOARDING} element={<Onboarding />} />
                      <Route element={<NavBarController />}>
                        <Route index element={<Home />} />
                        <Route
                          path={Page.CORE_VALUES}
                          element={<CoreValues />}
                        />
                        <Route path={Page.THEMES} element={<Themes />} />
                        <Route path={Page.GOALS} element={<Goals />} />
                        <Route
                          path={Page.GOAL_DETAILS}
                          element={<GoalDetails />}
                        />
                        <Route path={Page.JOURNAL} element={<Journal />} />
                        <Route
                          path={Page.JOURNAL_ENTRY}
                          element={<JournalEntry />}
                        />
                        <Route
                          path={Page.NOTIFICATIONS}
                          element={<Notifications />}
                        />
                        <Route
                          path={Page.PERSONALITY_TEST}
                          element={<PersonalityTests />}
                        />
                        <Route
                          path={Page.REFLECTIONS}
                          element={<Reflections />}
                        />
                        <Route
                          path={Page.REFLECTION_DETAILS}
                          element={<ReflectionDetails />}
                        />
                        <Route
                          path={Page.REFLECTION_TEMPLATE_DETAILS}
                          element={<ReflectionTemplateDetails />}
                        />
                        <Route path={Page.REVIEWS} element={<Reviews />} />
                        <Route
                          path={Page.REVIEW_DETAILS}
                          element={<ReviewDetails />}
                        />
                        <Route
                          path={Page.ACCOUNTABILITY_BUDDIES}
                          element={<AccountabilityBuddies />}
                        />
                        <Route path={Page.BUDDY_DETAILS} element={<Buddy />} />
                        <Route path={Page.SETTINGS} element={<Settings />} />
                        <Route
                          path={Page.USER_PROFILE}
                          element={<UserProfile />}
                        />
                        <Route path={Page.HOME} element={<Home />} />
                        <Route
                          element={<ProtectedRoute allowedRoles={["admin"]} />}
                        >
                          <Route
                            path={Page.ADMIN_PANEL}
                            element={<AdminPanel />}
                          />
                        </Route>
                      </Route>
                    </Route>
                    <Route element={<RedirectSignInUpRoute />}>
                      <Route path={Page.SIGN_IN} element={<SignIn />} />
                      <Route path={Page.SIGN_UP} element={<SignUp />} />
                    </Route>
                  </Routes>
                  {previousLocation && (
                    <Routes>
                      <Route
                        element={
                          <ProtectedRoute allowedRoles={["admin", "user"]} />
                        }
                      >
                        <Route
                          path={Page.PERSONALITY_TEST_MODAL}
                          element={<AddPersonalityTestModal />}
                        />
                      </Route>
                    </Routes>
                  )}
                  <GenericSnackbarController />
                </CssBaseline>
              </ThemeProvider>
            </LocalizationProvider>
          </OnlineProvider>
        </UserProvider>
      </NotificationProvider>
    </Provider>
  );
}

export default App;
