import { auth } from "../../../config/firebase";
import { MenuItemType } from "@beyondrealityapp/core/shared/types";
import MenuView from "./MenuView";
import { Page } from "@beyondrealityapp/core/shared/constants";
import { useState, useEffect } from "react";

const knowYourselfMenuItems: MenuItemType[] = [
  {
    text: "Core Values",
    icon: "Favorite",
    path: Page.CORE_VALUES,
    active: false,
  },
  {
    text: "Personality Tests",
    icon: "Quiz",
    path: Page.PERSONALITY_TEST,
    active: true,
  },
  {
    text: "Journal",
    icon: "TipsAndUpdates",
    path: Page.JOURNAL,
    active: true,
  },
  {
    text: "Reflections",
    icon: "DynamicFormIcon",
    path: Page.REFLECTIONS,
    active: true,
  },
];

const defineYourselfMenuItems: MenuItemType[] = [
  {
    text: "Goal Setter",
    icon: "Hiking",
    path: Page.GOAL_SETTER,
    active: false,
  },
];

const becomeYourselfMenuItems: MenuItemType[] = [
  {
    text: "Themes",
    icon: "Public",
    path: Page.THEMES,
    active: true,
  },
  {
    text: "Goals",
    icon: "CrisisAlert",
    path: Page.GOALS,
    active: true,
  },
  {
    text: "Reviews",
    icon: "EditNote",
    path: Page.REVIEWS,
    active: true,
  },
];

const communityMenuItems: MenuItemType[] = [
  {
    text: "Accountability Buddies",
    icon: "PeopleIcon",
    path: Page.ACCOUNTABILITY_BUDDIES,
    active: true,
  },
];

const settingsMenuItems: MenuItemType[] = [
  {
    text: "Settings",
    icon: "Settings",
    path: Page.SETTINGS,
    active: false,
  },
];

const adminPanelMenuItems: MenuItemType[] = [
  {
    text: "Admin Panel",
    icon: "AdminPanelSettingsIcon",
    path: Page.ADMIN_PANEL,
    active: true,
  },
];

const checkIfActiveMenuItems = (menuItems: MenuItemType[]) => {
  return menuItems.some((menuItem) => menuItem.active);
};

type MenuControllerProps = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
};

const MenuController: React.FC<MenuControllerProps> = ({
  open,
  onClose,
  onOpen,
}) => {
  const [showAdminPanel, setShowAdminPanel] = useState<boolean>(false);

  useEffect(() => {
    const checkAuth = async () => {
      const idTokenResult = await auth.currentUser?.getIdTokenResult();
      if (idTokenResult?.claims.admin) {
        setShowAdminPanel(true);
      }
    };
    checkAuth();
  }, []);
  return (
    <MenuView
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      checkIfActiveMenuItems={checkIfActiveMenuItems}
      knowYourselfMenuItems={knowYourselfMenuItems}
      defineYourselfMenuItems={defineYourselfMenuItems}
      becomeYourselfMenuItems={becomeYourselfMenuItems}
      communityMenuItems={communityMenuItems}
      settingsMenuItems={settingsMenuItems}
      showAdminPanel={showAdminPanel}
      adminPanelMenuItems={adminPanelMenuItems}
    />
  );
};

export default MenuController;
