import AddReflectionTemplateButtonController from "../../add_reflection_template_button/AddReflectionTemplateButtonController";
import Box from "@mui/material/Box";
import ListActions from "../../../layout/list_actions/ListActions";
import ReflectionTemplatesListController from "../../reflection_templates_list/ReflectionTemplatesListController";

const ReflectionsTemplateTabView = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <ListActions>
        <AddReflectionTemplateButtonController />
      </ListActions>
      <ReflectionTemplatesListController />
    </Box>
  );
};

export default ReflectionsTemplateTabView;
