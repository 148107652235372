import AddGoalController from "../components/goals/add_goal/AddGoalController";
import BackButtonController from "../components/layout/back_button/BackButtonController";
import PageContainer from "../components/layout/PageContainer";

const GoalDetails = () => {
  return (
    <PageContainer>
      <BackButtonController />
      <AddGoalController />
    </PageContainer>
  );
};

export default GoalDetails;
