import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { User } from "@beyondrealityapp/core/user/classes";
import UserProfileMenuController from "../user_profile_menu/UserProfileMenuController";
import WarningIcon from "@mui/icons-material/Warning";

type UserProfileAvatarViewProps = {
  isUserLoading: boolean;
  isUserError: boolean;
  user: User | undefined;
  userProfileMenuOpen: boolean;
  onUserProfileClick: () => void;
  onBackdropClick: () => void;
  refetchUser: () => void;
  buttonRef: React.RefObject<HTMLButtonElement>;
};

const UserProfileAvatarView: React.FC<UserProfileAvatarViewProps> = ({
  isUserLoading,
  isUserError,
  user,
  userProfileMenuOpen,
  onUserProfileClick,
  onBackdropClick,
  refetchUser,
  buttonRef,
}) => {
  return (
    <>
      {isUserLoading && !isUserError && (
        <CircularProgress color="primary" size={24} />
      )}
      <>
        <IconButton
          color="primary"
          edge="end"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          id="user-profile-button"
          ref={buttonRef}
          onClick={onUserProfileClick}
        >
          {isUserError ?? <WarningIcon color="error" />}
          {!isUserLoading && !isUserError && user ? (
            <Avatar
              alt="User Avatar"
              src={user?.profilePicture}
              sx={{ width: 32, height: 32 }}
            >
              {user?.name.charAt(0) ?? "U"}
            </Avatar>
          ) : null}
        </IconButton>
        <UserProfileMenuController
          open={userProfileMenuOpen}
          onClose={onBackdropClick}
          anchorEl={buttonRef.current}
        />
      </>
    </>
  );
};

export default UserProfileAvatarView;
