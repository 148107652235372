import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import { Draggable, Droppable, DragDropContext } from "@hello-pangea/dnd";
import { OnDragEndResponder } from "@hello-pangea/dnd";
import { useMemo } from "react";
import { TransitionGroup } from "react-transition-group";

type DraggableListViewProps = {
  id: string;
  listItems: React.ReactNode[];
  onDragEnd: OnDragEndResponder;
};

const DraggableListView: React.FC<DraggableListViewProps> = ({
  id,
  listItems,
  onDragEnd,
}) => {
  const memoizedListItems = useMemo(
    () =>
      listItems.map((item, index) => (
        <Collapse key={index}>
          <Draggable key={index} draggableId={index.toString()} index={index}>
            {(provided) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: 1,
                }}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <Card
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    padding: 2,
                  }}
                  variant="outlined"
                >
                  {item}
                </Card>
              </Box>
            )}
          </Draggable>
        </Collapse>
      )),
    [listItems]
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={id}>
          {(provided) => (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <TransitionGroup>{memoizedListItems}</TransitionGroup>
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default DraggableListView;
