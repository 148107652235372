import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import LoadingSpinner from "../../layout/LoadingSpinner";
import NoResults from "../../layout/NoResults";
import React from "react";
import ReflectionTemplateCardController from "../relfection_template_card/ReflectionTemplateCardController";
import { ReflectionClass } from "@beyondrealityapp/core/reflection/classes";

type ReflectionListViewProps = {
  reflections: ReflectionClass[];
  onClick: (reflectionId: string) => void;
  isLoading: boolean;
};

const ReflectionListView: React.FC<
  ReflectionListViewProps
> = ({ reflections, onClick, isLoading }) => {
  const showLoading = isLoading;
  const showReflections = reflections.length > 0 && !isLoading;
  const showNoReflections = reflections.length === 0 && !isLoading;
  return (
    <List
      sx={{
        width: "100%",
      }}
      dense
    >
      {showLoading && <LoadingSpinner />}
      {showNoReflections && (
        <Box
          sx={{
            marginTop: 2,
          }}
        >
          <NoResults message="It looks like you don't have any templates yet. Click on 'Add Reflection Template' to get started!" />
        </Box>
      )}
      {showReflections &&
        reflections.map((reflection) => (
          <ListItem key={reflection.id} disableGutters>
            <ReflectionTemplateCardController
              id={reflection.id}
              title={reflection.title}
              description={reflection.description}
              onClick={onClick}
            />
          </ListItem>
        ))}
    </List>
  );
};

export default ReflectionListView;
