import Box from "@mui/material/Box";
import ReflectionsTemplateTabView from "./reflections_template_tab/ReflectionsTemplateTabView";
import ReflectionsTabController from "./reflections_tab/ReflectionsTabController";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

type ReflectionTabsViewProps = {
  selectedTab: number;
  onTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
};

const ReflectionTabsView: React.FC<ReflectionTabsViewProps> = ({
  selectedTab,
  onTabChange,
}) => {
  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={onTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Reflections" />
        <Tab label="Reflection Templates" />
      </Tabs>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          width: "100%",
        }}
      >
        {selectedTab === 0 && <ReflectionsTabController />}
        {selectedTab === 1 && <ReflectionsTemplateTabView />}
      </Box>
    </>
  );
};

export default ReflectionTabsView;
