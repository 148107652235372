import { Page } from "@beyondrealityapp/core/shared/constants";
import ReflectionListView from "./ReflectionListView";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import { useDispatch } from "react-redux";
import { useFetchReflectionsQuery } from "../../../api/reflection";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ReflectionListController = () => {
  const {
    data: reflections,
    isError,
    isLoading,
    error,
  } = useFetchReflectionsQuery(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (reflectionId: string) => {
    navigate(`/${Page.REFLECTIONS}/${reflectionId}`);
  };

  useEffect(() => {
    if (isError) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "An error occurred while fetching your reflection records";
      dispatch(
        showSnackbar({
          message: errorMessage,
          severity: Severity.ERROR,
        })
      );
    }
  }, [isError, error, dispatch]);
  return (
    <ReflectionListView
      reflections={reflections || []}
      onClick={handleClick}
      isLoading={isLoading}
    />
  );
};

export default ReflectionListController;
