import NewReflectionModal from "./NewReflectionModal";
import { Page } from "@beyondrealityapp/core/shared/constants";
import React, { useState } from "react";
import { ReflectionTemplateClass } from "@beyondrealityapp/core/reflection/template/classes";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import { useFetchReflectionTemplatesQuery } from "../../../api/reflection";
import { useCreateReflectionMutation } from "../../../api/reflection";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { SelectChangeEvent } from "@mui/material";

type NewReflectionModalControllerProps = {
  open: boolean;
  onClose: () => void;
};

const NewReflectionModalController: React.FC<
  NewReflectionModalControllerProps
> = ({ open, onClose }) => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<ReflectionTemplateClass | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: reflectionTemplates,
    isLoading,
    isError,
    error,
  } = useFetchReflectionTemplatesQuery(undefined);
  const [createReflection] = useCreateReflectionMutation();

  const onSubmit = async () => {
    if (!selectedTemplate) return;
    setSubmitting(true);
    createReflection(selectedTemplate)
      .unwrap()
      .then((response) => {
        if (response) {
          navigate(`/${Page.REFLECTIONS}/${response.id}`);
        }
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: error.message,
            severity: Severity.ERROR,
          })
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value as string;
    const selectedTemplate = reflectionTemplates?.find(
      (template) => template.id === selectedValue
    );
    setSelectedTemplate(selectedTemplate ?? null);
  };

  return (
    <NewReflectionModal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      submitting={submitting}
      isError={isError}
      error={error as Error}
      isLoading={isLoading}
      value={selectedTemplate?.id ?? ""}
      reflectionTemplates={reflectionTemplates ?? []}
      onChange={onChange}
    />
  );
};

export default NewReflectionModalController;
