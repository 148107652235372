import React from "react";
import ListCardController from "../../layout/list_card/ListCardController";

type ReflectionTemplateCardViewProps = {
  id: string;
  title: string;
  description: string;
  onClick: (reflectionTemplateId: string) => void;
  image?: string;
};

const ReflectionTemplateCardView: React.FC<ReflectionTemplateCardViewProps> = ({
  id,
  title,
  description,
  onClick,
  image,
}) => {
  return (
    <ListCardController
      key={id}
      title={title}
      subtitle={description}
      image={image}
      onClickHandler={() => onClick(id)}
    />
  );
};

export default ReflectionTemplateCardView;
