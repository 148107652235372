import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

type FormContainerProps = {
  children: React.ReactNode;
};

const FormContainer: React.FC<FormContainerProps> = ({
  children,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: 4,
          width: "80%",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FormContainer;
