import ReflectionTabsView from "./ReflectionTabsView";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const ReflectionTabsController = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (event: any, newValue: number) => {
    setActiveTab(newValue);

    const newSearchParams = new URLSearchParams(searchParams);

    if (newValue === 0) {
      newSearchParams.set("tab", "reflections");
    }
    if (newValue === 1) {
      newSearchParams.set("tab", "reflection-templates");
    }

    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    const tab = searchParams.get("tab");

    if (tab === "reflections") {
      setActiveTab(0);
      return;
    }
    if (tab === "reflection-templates") {
      setActiveTab(1);
      return;
    }
  }, [searchParams]);

  return (
    <ReflectionTabsView selectedTab={activeTab} onTabChange={handleTabChange} />
  );
};

export default ReflectionTabsController;
