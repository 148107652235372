import NotificationSettingsController from "../components/notifications/notification_settings/NotificationSettingsController";
import PageContainer from "../components/layout/PageContainer";

const Notifications = () => {
  return (
    <PageContainer>
      <NotificationSettingsController />
    </PageContainer>
  );
};

export default Notifications;
